import { useEffect, useState } from 'react';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Shimmer } from 'react-shimmer';
import apiCall from '../../../../api/apiCall';
import { getAccessToken } from '../../../Login/Auth';
import Button from '../../../Shared/Form/Button/Button';
import Form from '../../../Shared/Form/Form';
import TextInput from '../../../Shared/Form/TextInput/TextInput';
import './EditTransaction.css';
const EditTransaction = (props) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    let token = getAccessToken();
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState();
    const [transactionData, setTransacitonData] = useState();

    const [transactionState, setTransactionState] = useState([]);
    const [allTransactionsData, setAllTransactionsData] = useState();
    if (token != null) token = token.replace(/['"]+/g, '');
    useEffect(() => {
        document.title = 'Update Transaction';
    });
    const gotoPrevious = (transaction_id) => {
        setMsg('');
        const i = allTransactionsData.indexOf(transactionData);

        if (i === 0) {
            setMsg('No Previous Transactions');
            return;
        }

        navigate('/dashboard/edit/transaction/' + allTransactionsData[i - 1].transaction_id, {
            state: { data: allTransactionsData[i - 1], allData: allTransactionsData }
        });
    };
    const gotoNext = (transaction_id) => {
        setMsg('');
        const i = allTransactionsData.indexOf(transactionData);
        if (i === allTransactionsData.length - 1) {
            setMsg('No Next Transaction');
            return;
        }
        navigate('/dashboard/edit/transaction/' + allTransactionsData[i + 1].transaction_id, {
            state: { data: allTransactionsData[i + 1], allData: allTransactionsData }
        });
    };

    const getTransactions = async (e) => {
        setIsLoading(true);

        const response = await apiCall(`/transaction/${id}`);
        if (response.ok) {
            setTransacitonData(response.data);
            // setMsg('Success');
        } else {
            setMsg(response.msg);
        }

        setIsLoading(false);
    };

    const updateTransaction = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const response = await apiCall(`/transaction/${transactionData.transaction_id}`, 'PATCH', {
            status: transactionData.status
        });

        if (response.ok) {
            setMsg(response.msg);
            setAllTransactionsData(() =>
                allTransactionsData?.map((data, i) => {
                    if (data.transaction_id === transactionData.transaction_id)
                        return transactionData;
                    return data;
                })
            );
        } else {
            setMsg(response.msg);
        }

        setIsLoading(false);
    };
    //
    useEffect(() => {
        if (location.state) {
            setTransacitonData(location.state.data);
            setAllTransactionsData(location.state.allData);
        } else getTransactions();
    }, [location.state]);

    return (
        <>
            {allTransactionsData?.length > 0 && (
                <>
                    <AiOutlineArrowLeft
                        onClick={() => gotoPrevious(transactionData?.transaction_id)}
                        className="prev-next-icon-left"
                    />
                    <AiOutlineArrowRight
                        onClick={() => gotoNext(transactionData?.transaction_id)}
                        className="prev-next-icon-right"
                    />
                </>
            )}
            <div className="transaction-form-area">
                <h3>Update Transaction</h3>
                {msg && <div className="msg">{msg}</div>}
                {transactionData && (
                    <Form method="post" className={'transaction-form form-area'}>
                        {isLoading && (
                            <>
                                <Shimmer width={'90%'} height={'50px'} />
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <Shimmer width={'90%'} height={'50px'} />
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <Shimmer width={'90%'} height={'50px'} />
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <Shimmer width={'90%'} height={'50px'} />
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                            </>
                        )}
                        {!isLoading && (
                            <>
                                <TextInput
                                    label="Transaction Id"
                                    type="text"
                                    required
                                    value={transactionData.transaction_id}
                                    disabled
                                />
                                {transactionData.status !== 'Hold' ||
                                transactionData.status !== 'Pending' ? (
                                    <div className="form-field">
                                        <label>Transaction Status</label>
                                        <select
                                            className="select-box"
                                            onChange={(e) =>
                                                setTransacitonData({
                                                    ...transactionData,
                                                    status: e.target.value
                                                })
                                            }
                                        >
                                            <option
                                                selected={transactionData.status === 'Select'}
                                                key="Select"
                                                value="Select"
                                            >
                                                Select
                                            </option>
                                            <option
                                                key="Complete"
                                                value="Complete"
                                                selected={transactionData.status === 'Complete'}
                                            >
                                                Complete
                                            </option>
                                            <option
                                                key="Reject"
                                                value="Reject"
                                                selected={transactionData.status === 'Reject'}
                                            >
                                                Reject
                                            </option>
                                            <option
                                                key="Settlement"
                                                value="Settlement"
                                                selected={transactionData.status === 'Settlement'}
                                            >
                                                Settlement
                                            </option>
                                        </select>
                                    </div>
                                ) : (
                                    <TextInput
                                        label="Transaction Status"
                                        type="text"
                                        required
                                        value={transactionData.status}
                                        disabled
                                    />
                                )}
                                {/* {transactionData.status === 'Settlement' ? (
                                    <div className="form-field">
                                        <label>Transaction Status</label>
                                        <select
                                            className="select-box"
                                            onChange={(e) =>
                                                setTransacitonData({
                                                    ...transactionData,
                                                    status: e.target.value
                                                })
                                            }
                                        >
                                            <option key="Select" value="Select">
                                                Select
                                            </option>
                                            <option key="Complete" value="Complete">
                                                Complete
                                            </option>
                                            <option key="Reject" value="Reject">
                                                Reject
                                            </option>
                                        </select>
                                    </div>
                                ) : transactionData.status === 'Complete' ||
                                  transactionData.status === 'Reject' ? (
                                    <div className="form-field">
                                        <label>Transaction Status</label>
                                        <select
                                            className="select-box"
                                            onChange={(e) =>
                                                setTransacitonData({
                                                    ...transactionData,
                                                    status: e.target.value
                                                })
                                            }
                                        >
                                            <option key="Select" value="Select">
                                                Select
                                            </option>
                                            <option key="Settlement" value="Settlement">
                                                Settlement
                                            </option>
                                        </select>
                                    </div>
                                ) : (
                                    <TextInput
                                        label="Transaction Status"
                                        type="text"
                                        required
                                        value={transactionData.status}
                                        disabled
                                    />
                                )} */}
                                <TextInput
                                    label="Amount In"
                                    type="text"
                                    value={transactionData.amount_in}
                                    disabled
                                />

                                <TextInput
                                    label="Type"
                                    type="text"
                                    value={
                                        transactionData?.payment?.invoice_id
                                            ? 'Invoice'
                                            : transactionData?.payment?.paylink_id
                                            ? 'LinkPay'
                                            : ''
                                    }
                                    disabled
                                />
                                <TextInput
                                    label="User Name"
                                    type="text"
                                    value={transactionData.user.fullName}
                                    disabled
                                />
                                <TextInput
                                    label="User Email"
                                    type="text"
                                    value={transactionData.user.email}
                                    disabled
                                />
                            </>
                        )}
                        <div className="form-button">
                            <Button className={'btn-add'} onClick={updateTransaction}>
                                Update
                            </Button>
                            <Button onClick={() => navigate(-1)} className={'btn-close'}>
                                Cancel
                            </Button>
                        </div>
                    </Form>
                )}
            </div>
        </>
    );
};

export default EditTransaction;
