import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import apiCall from '../../api/apiCall';
import Loading from '../Loading/Loading';
import Button2 from '../Shared/Form/Button/Button2';
import FormTitle from '../Shared/Form/FormTitle/FormTitle';
import TextInput from '../Shared/Form/TextInput/TextInput';
import FormLayout from '../Shared/FormLayout/FormLayout';
import { setTokens } from './Auth';
import './Login.css';
const Login = (props) => {
    const navigate = useNavigate();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState();
    useEffect(() => {
        document.title = 'Login';
    });
    let deviceID = localStorage.getItem('deviceID');
    if (!deviceID) {
        deviceID = uuidv4();
        localStorage.setItem('deviceID', deviceID);
    }

    const partnerAdminLogin = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const response = await apiCall(`/login`, 'POST', {
            email,
            password,
            deviceID
        });

        if (response.ok) {
            setTokens(response.data.tokens);
            setMsg('Login Successful');
            if (window.location.pathname === '/') navigate('/dashboard');
            window.location.reload();
        } else {
            setMsg(response.message);
        }

        setIsLoading(false);
    };

    const title = 'Admin Login';
    const subtitle = 'Enter your registered email and password for login';

    return (
        <FormLayout className={'form-style'}>
            {isLoading && <Loading />}
            <div className="form-top">
                <div>
                    <p>Don’t have an account yet?</p>
                </div>
                <div>
                    <a href="/signup" className="auth-form-button">
                        Sign Up
                    </a>
                </div>
            </div>
            <div className="form-fields-area">
                <FormTitle title={title} subtitle={subtitle} />
                {msg && isLoading && <div className="msg">{msg}</div>}

                <form action="" onSubmit={partnerAdminLogin}>
                    <TextInput
                        onChange={(e) => setEmail(e.target.value)}
                        type="text"
                        placeholder="Email address"
                        label={'Email address'}
                        required
                    />

                    <TextInput
                        type="password"
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                        label={'Password'}
                        required
                    />

                    <div className="forgot-password">
                        <a href="/recover-password">Forgot Password</a>
                    </div>
                    <div>
                        <Button2 type="submit" disabled={!(email && password)}>
                            Login
                        </Button2>
                    </div>
                </form>
            </div>
        </FormLayout>
    );
};

export default Login;
