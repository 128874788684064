import apiCall from 'api/apiCall';
import moment from 'moment/moment';
import { useEffect, useState } from 'react';
import { FiEdit } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { Image, Shimmer } from 'react-shimmer';
import LinkButton from '../../Shared/LinkButton/LinkButton';
import NoDataFound from '../../Shared/NoDataFound/NoDataFound';
import './Document.css';
const Document = () => {
    const linkBtn = {
        text: 'Add New',
        btnBG: '#2980FF',
        btnColor: '#ffffff',
        link: '/dashboard/view/documents'
    };

    const [isLoading, setIsLoading] = useState(false);
    const [documentData, setDocumentData] = useState();
    const [statusName, setStatusName] = useState('All');
    const [filterData, setFilteredData] = useState();
    const [msg, setMsg] = useState();
    useEffect(() => {
        document.title = 'Document';
    });

    const getDocuments = async () => {
        setIsLoading(true);

        const response = await apiCall(`/documents`);

        if (response.ok) {
            setDocumentData(response.data);
            setMsg('Success');
        } else {
            setMsg(response.msg);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        getDocuments();
    }, []);

    useEffect(() => {
        if (documentData && statusName !== 'All') {
            const data = documentData.filter((value) => value.status === statusName);
            setFilteredData(data);
        } else {
            setFilteredData(documentData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusName, documentData]);
    let fields;
    if (filterData) {
        fields = filterData.map((data) => (
            <tr key={data.id}>
                <th>
                    <span className="checkbox">
                        <input type="checkbox"></input>
                    </span>
                    <span>#{data.id}</span>
                </th>
                <th>
                    {data.document_type.option === 'NID' ? (
                        <>
                            <a
                                className="fileImage"
                                target="_blank"
                                href={`${process.env.REACT_APP_UPLOAD_URL}/image/${
                                    data.file_key.split(',')[0]
                                }`}
                                rel="noreferrer"
                            >
                                <Image
                                    className="fileImage"
                                    alt="file_key"
                                    src={
                                        data && data.file_key
                                            ? `${process.env.REACT_APP_UPLOAD_URL}/image/${
                                                  data.file_key.split(',')[0]
                                              }`
                                            : 'https://via.placeholder.com/150'
                                    }
                                    fallback={<Shimmer width={'50px'} height={'50px'} />}
                                />
                            </a>
                            <a
                                className="fileImage"
                                target="_blank"
                                href={`${process.env.REACT_APP_UPLOAD_URL}/image/${
                                    data.file_key.split(',')[1]
                                }`}
                                rel="noreferrer"
                            >
                                <Image
                                    alt="file_key"
                                    src={
                                        data && data.file_key
                                            ? `${process.env.REACT_APP_UPLOAD_URL}/image/${
                                                  data.file_key.split(',')[1]
                                              }`
                                            : 'https://via.placeholder.com/150'
                                    }
                                    fallback={<Shimmer width={'50px'} height={'50px'} />}
                                />
                            </a>
                        </>
                    ) : (
                        <>
                            <a
                                className="fileImage"
                                target="_blank"
                                href={`${process.env.REACT_APP_UPLOAD_URL}/image/${data.file_key}`}
                                rel="noreferrer"
                            >
                                <Image
                                    alt="file_key"
                                    src={
                                        data && data.file_key
                                            ? `${process.env.REACT_APP_UPLOAD_URL}/image/${data.file_key}`
                                            : 'https://via.placeholder.com/150'
                                    }
                                    fallback={<Shimmer width={'50px'} height={'50px'} />}
                                />
                            </a>
                        </>
                    )}
                </th>
                <th>{data.status}</th>
                <th>{data.document_type.option}</th>
                <th>{data.user.fullName}</th>
                <th>{moment(new Date(data.createdAt), 'MMDDYYYY').fromNow()}</th>
                <th>
                    <div className="action-btn">
                        <Link
                            className="edit-icon"
                            to={`/dashboard/edit/document/${data.id}`}
                            state={{ data, allData: documentData }}
                        >
                            <FiEdit />
                        </Link>
                    </div>
                </th>
            </tr>
        ));
    }

    return (
        <>
            <div className="verification-area">
                <div className="verification-table-area">
                    <div className="table-top">
                        <div className="verification-result-show">
                            <span>Status: </span>
                            <select
                                defaultValue={statusName}
                                onChange={(e) => setStatusName(e.target.value)}
                            >
                                <option key="All" value="All">
                                    All
                                </option>
                                <option key="Pending" value="Pending">
                                    Pending
                                </option>
                                <option key="Approved" value="Approved">
                                    Approved
                                </option>
                                <option key="Rejected" value="Rejected">
                                    Rejected
                                </option>
                            </select>
                        </div>
                        <LinkButton linkBtn={linkBtn} />
                    </div>
                    <div className="verification-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <span className="checkbox">
                                            <input type="checkbox"></input>
                                        </span>
                                        <span>Document Id</span>
                                    </th>
                                    <th>Image</th>
                                    <th>Status</th>
                                    <th>Document Name</th>
                                    <th>User Name</th>
                                    <th>Uploaded Date</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            {!isLoading && msg && (
                                <tbody>{fields ? fields : <NoDataFound />}</tbody>
                            )}
                        </table>
                        {isLoading && (
                            <>
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Document;
