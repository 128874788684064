import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import SettingsSidebar from '../../Shared/SettingsSidebar/SettingsSidebar';
import './Settings.css';

const Settings = () => {
    useEffect(() => {
        document.title = 'Settings';
    }, []);
    return (
        <div className="settings-wrapper">
            <SettingsSidebar />
            <div className="settings-main">
                <Outlet />
            </div>
        </div>
    );
};

export default Settings;
