import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Shimmer } from 'react-shimmer';
import apiCall from '../../../../api/apiCall';
import { getAccessToken } from '../../../Login/Auth';
import Button from '../../../Shared/Form/Button/Button';
import Form from '../../../Shared/Form/Form';
import TextInput from '../../../Shared/Form/TextInput/TextInput';
import './AddExchangeRate.css';
const AddExchangeRate = (props) => {
    const navigate = useNavigate();
    let token = getAccessToken();
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState();
    const [currencyData, setCurrencyData] = useState([]);
    const [from_currency, setFromCurrency] = useState();
    const [to_currency, setToCurrency] = useState();
    const [exchange_rate, setExchangeRate] = useState();
    if (token != null) token = token.replace(/['"]+/g, '');
    useEffect(() => {
        document.title = 'Add Exchange Rate';
    });

    const addExchnageRate = async (e) => {
        e.preventDefault();

        if (from_currency === to_currency) {
            setMsg('Please select different currency');
            return;
        }
        setIsLoading(true);

        const response = await apiCall(`/exchange_rate`, 'POST', {
            from_currency,
            to_currency,
            exchange_rate
        });
        if (response.ok) {
            setMsg('Success');
        } else {
            setMsg(response.msg);
        }

        setIsLoading(false);
    };

    const getCurrencies = async () => {
        setIsLoading(true);

        const response = await apiCall(`/currencies`);

        if (response.ok) {
            setCurrencyData(response.data);
            setMsg('Success');
        } else {
            setMsg(response.msg);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        if (currencyData.length > 0) {
            setFromCurrency(currencyData[0].id.toString());
            setToCurrency(currencyData[currencyData.length - 1].id.toString());
        }
    }, [currencyData]);

    useEffect(() => {
        getCurrencies();
    }, []);

    return (
        <>
            <div className="add-exchange-form-area">
                <h3>Add Exchange Rate</h3>
                {msg && <div className="msg">{msg}</div>}
                <Form method="post" className={'add-exchange-form form-area'}>
                    {isLoading && (
                        <>
                            <Shimmer width={'90%'} height={'50px'} />
                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                            <Shimmer width={'90%'} height={'50px'} />
                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                        </>
                    )}
                    {!isLoading && (
                        <>
                            <div className="form-field">
                                <label>From Currency</label>
                                <select
                                    className="select-box"
                                    onChange={(e) => setFromCurrency(e.target.value)}
                                    value={from_currency}
                                >
                                    {currencyData &&
                                        currencyData.map((data) => (
                                            <option key={data.id} value={data.id}>
                                                {data.currency_name}
                                            </option>
                                        ))}
                                </select>
                            </div>
                            <div className="form-field">
                                <label>To Currency</label>
                                <select
                                    className="select-box"
                                    onChange={(e) => setToCurrency(e.target.value)}
                                    value={to_currency}
                                >
                                    {currencyData &&
                                        currencyData.map((data) => (
                                            <option key={data.id} value={data.id}>
                                                {data.currency_name}
                                            </option>
                                        ))}
                                </select>
                            </div>
                            <TextInput
                                label="Exchange Rate"
                                type="text"
                                name="exchange_rate"
                                required
                                onChange={(e) => setExchangeRate(e.target.value)}
                            />
                        </>
                    )}
                    <div className="form-button">
                        <Button
                            onClick={addExchnageRate}
                            disabled={!(from_currency && to_currency && exchange_rate)}
                            className={'btn-add'}
                        >
                            Add
                        </Button>
                        <Button onClick={() => navigate(-1)} className={'btn-close'}>
                            Cancel
                        </Button>
                    </div>
                </Form>
            </div>
        </>
    );
};

export default AddExchangeRate;
