import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Shimmer } from 'react-shimmer';
import apiCall from '../../api/apiCall';
import Button2 from '../Shared/Form/Button/Button2';
import FormTitle from '../Shared/Form/FormTitle/FormTitle';
import TextInput from '../Shared/Form/TextInput/TextInput';
import FormLayout from '../Shared/FormLayout/FormLayout';

const ResetPassword = (props) => {
    const navigate = useNavigate();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [msg, setMsg] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const { token } = useParams();
    const title = 'Reset your password';
    const subtitle = 'At least 8 characters with uppercase and lowercase letters #';
    useEffect(() => {
        document.title = 'Reset Password';
    });

    const changePassword = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const response = await apiCall(`/reset_password?token=${token}`, 'POST', {
            password,
            confirmPassword
        });
        setMsg(response.msg);
        if (response.ok) {
        } else {
        }

        setIsLoading(false);
    };
    useEffect(() => {
        if (password !== confirmPassword) {
            setMsg("New password and confirm password doesn't match");
        } else {
            setMsg('');
        }
    }, [password, confirmPassword]);

    return (
        <FormLayout className={'form-style'}>
            <div className="form-fields-area">
                <FormTitle title={title} subtitle={subtitle} />
                {msg && password && confirmPassword && <div className="msg">{msg}</div>}
                <form onSubmit={changePassword} action="">
                    {isLoading && (
                        <>
                            <Shimmer width={'90%'} height={'50px'} />
                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                            <Shimmer width={'90%'} height={'50px'} />
                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                        </>
                    )}
                    {!isLoading && (
                        <>
                            <TextInput
                                type="password"
                                placeholder="New Password"
                                onChange={(e) => setPassword(e.target.value)}
                                label={'New Password'}
                                required
                            />
                            <TextInput
                                type="password"
                                placeholder="Confirm Password"
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                label={'Confirm Password'}
                                required
                            />
                        </>
                    )}
                    <div>
                        <Button2 type="submit" disabled={!(password && confirmPassword)}>
                            Confirm
                        </Button2>
                    </div>
                </form>
            </div>
        </FormLayout>
    );
};

export default ResetPassword;
