import { useEffect, useState } from 'react';
import apiCall from '../../api/apiCall';
import Button2 from '../Shared/Form/Button/Button2';
import FormTitle from '../Shared/Form/FormTitle/FormTitle';
import TextInput from '../Shared/Form/TextInput/TextInput';
import FormLayout from '../Shared/FormLayout/FormLayout';
function RecoverPassword() {
    const [email, setEmail] = useState();
    const [msg, setMsg] = useState();
    const title = 'Recover Password';
    const subtitle = 'A link will be sent to your email address to setup new password';
    useEffect(() => {
        document.title = 'Forgot Password';
    });

    const recoverPassword = async (e) => {
        e.preventDefault();

        const response = await apiCall(`/forgot_password`, 'POST', {
            email
        });
        setMsg(response.msg);
        if (response.ok) {
        } else {
        }
    };
    return (
        <FormLayout>
            <div className="form-fields-area">
                <FormTitle title={title} subtitle={subtitle} />
                {msg && <div className="msg">{msg}</div>}
                <form>
                    <TextInput
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        placeholder="Email address"
                        label={'Email address'}
                        required
                    />

                    <div className="form-button-area">
                        <Button2 disabled={!email} onClick={recoverPassword} type="submit">
                            Recover Password
                        </Button2>
                    </div>
                </form>
            </div>
        </FormLayout>
    );
}

export default RecoverPassword;
