import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Shimmer } from 'react-shimmer';
import apiCall from '../../../../api/apiCall';
import { getAccessToken } from '../../../Login/Auth';
import Button from '../../../Shared/Form/Button/Button';
import Form from '../../../Shared/Form/Form';
import TextInput from '../../../Shared/Form/TextInput/TextInput';
import './EditGatewayFee.css';
const EditGatewayFee = (props) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    let token = getAccessToken();
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState();
    const [gatewayFee, setGatewayFee] = useState();

    const [paymentMethods, setPaymentMethods] = useState([]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
    const [selectedFeeCurrency, setSelectedFeeCurrency] = useState();
    const [feeName, setFeeName] = useState();
    const [value, setValue] = useState();
    const [feeType, setFeeType] = useState('Fixed');
    const [currencyData, setCurrencyData] = useState();

    useEffect(() => {
        document.title = 'Update Gateway Fee';
    });
    const getPaymentMethods = async () => {
        setIsLoading(true);

        const response = await apiCall(`/payment_methods`);

        if (response.ok) {
            setPaymentMethods(response.data);
            setMsg('Success');
        } else {
            setMsg(response.msg);
        }

        setIsLoading(false);
    };
    const getCurrencies = async () => {
        setIsLoading(true);

        const response = await apiCall(`/currencies`);

        if (response.ok) {
            setCurrencyData(response.data);
            setMsg('Success');
        } else {
            setMsg(response.msg);
        }

        setIsLoading(false);
    };
    const getGatewayFee = async (e) => {
        setIsLoading(true);

        const response = await apiCall(`/gateway_fee/${id}`);
        console.log(response);
        setMsg(response.msg);
        if (response.ok) {
            setGatewayFee(response.data);
        } else {
        }

        setIsLoading(false);
    };
    const updateGatewayFee = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const response = await apiCall(`/gateway_fee/${id}`, 'PATCH', {
            fee_currency: selectedFeeCurrency,
            payment_method_id: selectedPaymentMethod,
            name: feeName,
            value,
            fee_type: feeType
        });
        console.log(response);

        if (response.ok) {
            setMsg(response.msg);
            // window.history.replaceState({}, document.title);
        } else {
            setMsg(response.msg);
        }

        setIsLoading(false);
    };
    useEffect(() => {
        getPaymentMethods();
        getCurrencies();
        if (location.state) setGatewayFee(location.state);
        else getGatewayFee();
    }, []);
    useEffect(() => {
        if (!gatewayFee) return;
        setFeeType(gatewayFee.fee_type);
        setSelectedPaymentMethod(gatewayFee.payment_method_id);
        setSelectedFeeCurrency(gatewayFee.fee_currency);
        setFeeName(gatewayFee.name);
        setValue(gatewayFee.value);
    }, [gatewayFee]);

    return (
        <>
            <div className="edit-exchange-form-area">
                <h3>Edit Exchange Rate</h3>
                {msg && <div className="msg">{msg}</div>}
                {gatewayFee && (
                    <Form method="post" className={'edit-exchange-form form-area'}>
                        {isLoading && (
                            <>
                                <Shimmer width={'90%'} height={'50px'} />
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <Shimmer width={'90%'} height={'50px'} />
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <Shimmer width={'90%'} height={'50px'} />
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <Shimmer width={'90%'} height={'50px'} />
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                            </>
                        )}
                        {!isLoading && (
                            <>
                                <>
                                    <div className="form-field">
                                        <label>Payment Method</label>
                                        <select
                                            className="select-box"
                                            onChange={(e) =>
                                                setSelectedPaymentMethod(e.target.value)
                                            }
                                            value={selectedPaymentMethod}
                                        >
                                            {paymentMethods &&
                                                paymentMethods.map((data) => (
                                                    <option key={data.id} value={data.id}>
                                                        {data.method_name}
                                                    </option>
                                                ))}
                                        </select>
                                    </div>
                                    <div className="form-field">
                                        <label>Fee Currency</label>
                                        <select
                                            className="select-box"
                                            onChange={(e) => setSelectedFeeCurrency(e.target.value)}
                                            value={selectedFeeCurrency}
                                        >
                                            {currencyData &&
                                                currencyData.map((data) => (
                                                    <option key={data.id} value={data.id}>
                                                        {data.currency_name}
                                                    </option>
                                                ))}
                                        </select>
                                    </div>
                                    <div className="form-field">
                                        <label>Fee Type</label>
                                        <select
                                            className="select-box"
                                            onChange={(e) => setFeeType(e.target.value)}
                                            value={feeType}
                                        >
                                            <option key={1} value={'Percentage'}>
                                                Percentage
                                            </option>
                                            <option key={2} value={'Fixed'}>
                                                Fixed
                                            </option>
                                        </select>
                                    </div>
                                    <TextInput
                                        label="Value"
                                        type="text"
                                        required
                                        value={value}
                                        onChange={(e) => setValue(e.target.value)}
                                    />
                                    <TextInput
                                        label="Fee Name (Optional)"
                                        type="text"
                                        value={feeName}
                                        onChange={(e) => setFeeName(e.target.value)}
                                    />
                                </>
                            </>
                        )}
                        <div className="form-button">
                            <Button className={'btn-add'} onClick={updateGatewayFee}>
                                Update
                            </Button>
                            <Button onClick={() => navigate(-1)} className={'btn-close'}>
                                Cancel
                            </Button>
                        </div>
                    </Form>
                )}
            </div>
        </>
    );
};

export default EditGatewayFee;
