import { useEffect, useState } from 'react';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Shimmer } from 'react-shimmer';
import apiCall from '../../../../api/apiCall';
import Button from '../../../Shared/Form/Button/Button';
import Form from '../../../Shared/Form/Form';
import TextInput from '../../../Shared/Form/TextInput/TextInput';
import './EditWithdraw.css';
const EditWithdraw = (props) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState();
    const [withdrawData, setWithdrawData] = useState();
    const [allWithdrawData, setAllWithdrawData] = useState();
    useEffect(() => {
        document.title = 'Update Withdraw';
    });

    const gotoPrevious = (withdraw_id) => {
        setMsg('');
        const i = allWithdrawData.indexOf(withdrawData);

        if (i === 0) {
            setMsg('No Previous Withdraw');
            return;
        }

        // setDocumentData(allDocumentsData[i - 1]);
        navigate('/dashboard/edit/withdraw/' + allWithdrawData[i - 1].withdraw_id, {
            state: { data: allWithdrawData[i - 1], allData: allWithdrawData }
        });
    };
    const gotoNext = (withdraw_id) => {
        setMsg('');
        const i = allWithdrawData.indexOf(withdrawData);
        if (i === allWithdrawData.length - 1) {
            setMsg('No Next Withdraw');
            return;
        }

        navigate('/dashboard/edit/withdraw/' + allWithdrawData[i + 1].withdraw_id, {
            state: { data: allWithdrawData[i + 1], allData: allWithdrawData }
        });
    };

    useEffect(() => {
        if (location.state) {
            setWithdrawData(location.state.data);
            setAllWithdrawData(location.state.allData);
        } else getWithdraw();
    }, [location.state]);

    const getWithdraw = async (e) => {
        setIsLoading(true);
        const response = await apiCall(`/withdraw/${id}`);
        if (response.ok) {
            setWithdrawData(response.data);
        } else {
            setMsg(response.msg);
        }
        setIsLoading(false);
    };
    const updateWithdraw = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const response = await apiCall(`/withdraw/${withdrawData.withdraw_id}`, 'PATCH', {
            status: withdrawData.status
        });
        if (response.ok) {
            setMsg(response.msg);
            setAllWithdrawData(() =>
                allWithdrawData?.map((data, i) => {
                    if (data.withdraw_id === withdrawData.withdraw_id) return withdrawData;
                    return data;
                })
            );
        } else {
            setMsg(response.msg);
        }

        setIsLoading(false);
    };

    return (
        <>
            {allWithdrawData?.length > 0 && (
                <>
                    <AiOutlineArrowLeft
                        onClick={() => gotoPrevious(withdrawData?.withdraw_id)}
                        className="prev-next-icon-left"
                    />
                    <AiOutlineArrowRight
                        onClick={() => gotoNext(withdrawData?.withdraw_id)}
                        className="prev-next-icon-right"
                    />
                </>
            )}
            <div className="withdraw-form-area">
                <h3>Update Withdraw</h3>
                {msg && <div className="msg">{msg}</div>}
                {withdrawData && (
                    <Form method="post" className={'withdraw-form form-area'}>
                        {isLoading && (
                            <>
                                <Shimmer width={'90%'} height={'50px'} />
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <Shimmer width={'90%'} height={'50px'} />
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <Shimmer width={'90%'} height={'50px'} />
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <Shimmer width={'90%'} height={'50px'} />
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                            </>
                        )}
                        {!isLoading && (
                            <>
                                <TextInput
                                    label="Withdraw Id"
                                    type="text"
                                    required
                                    value={withdrawData.withdraw_id}
                                    disabled
                                />
                                {withdrawData && withdrawData.status === 'Hold' ? (
                                    <div className="form-field">
                                        <label>Withdraw Status</label>
                                        <select
                                            className="select-box"
                                            onChange={(e) =>
                                                setWithdrawData({
                                                    ...withdrawData,
                                                    status: e.target.value
                                                })
                                            }
                                        >
                                            <option key="Select" value="Select">
                                                Select
                                            </option>
                                            <option key="Deposited" value="Deposited">
                                                Deposited
                                            </option>
                                            <option key="Rejected" value="Rejected">
                                                Rejected
                                            </option>
                                        </select>
                                    </div>
                                ) : (
                                    <TextInput
                                        label="Withdraw Status"
                                        type="text"
                                        required
                                        value={withdrawData.status}
                                        disabled
                                    />
                                )}
                                <TextInput
                                    label="Withdraw Amount (USD)"
                                    type="text"
                                    value={withdrawData.withdraw_amount}
                                    disabled
                                />
                                <TextInput
                                    label="Receive Amount (BDT)"
                                    type="text"
                                    value={withdrawData.received_amount}
                                    disabled
                                />
                                <TextInput
                                    label="Withdraw Fee"
                                    type="text"
                                    value={withdrawData.withdraw_fee}
                                    disabled
                                />
                                <TextInput
                                    label="Exchange Rate"
                                    type="text"
                                    value={withdrawData.exchange_rate}
                                    disabled
                                />
                                <TextInput
                                    label="Bank Name"
                                    type="text"
                                    value={withdrawData.bank_name}
                                    disabled
                                />
                                <TextInput
                                    label="Bank Account Name"
                                    type="text"
                                    value={withdrawData.account_name}
                                    disabled
                                />
                                <TextInput
                                    label="Bank Account Number"
                                    type="text"
                                    value={withdrawData.account_number}
                                    disabled
                                />
                            </>
                        )}
                        <div className="form-button">
                            <Button className={'btn-add'} onClick={updateWithdraw}>
                                Update
                            </Button>
                            <Button onClick={() => navigate(-1)} className={'btn-close'}>
                                Cancel
                            </Button>
                        </div>
                    </Form>
                )}
            </div>
        </>
    );
};

export default EditWithdraw;
