import { useEffect, useState } from 'react';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { Shimmer } from 'react-shimmer';
import apiCall from '../../../api/apiCall';
import LinkButton from '../../Shared/LinkButton/LinkButton';
import NoDataFound from '../../Shared/NoDataFound/NoDataFound';
import './GatewayFee.css';

const GatewayFee = (props) => {
    const linkBtn = {
        text: 'Add New',
        btnBG: '#2980FF',
        btnColor: '#ffffff',
        link: '/dashboard/add/gateway_fee'
    };

    const [isLoading, setIsLoading] = useState(false);
    const [searchedData, setSearchedData] = useState();
    const [gatewayFees, setGatewayFees] = useState();
    const [fields, setFields] = useState();

    const [msg, setMsg] = useState();
    useEffect(() => {
        document.title = 'Gateway Fees';
    });

    const getGatewayFees = async () => {
        setIsLoading(true);

        const response = await apiCall(`/gateway_fee`);
        if (response.ok) {
            setGatewayFees(response.data);

            setMsg('Success');
        } else {
            setMsg(response.msg);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        getGatewayFees();
    }, []);

    useEffect(() => {
        if (gatewayFees) {
            setSearchedData(gatewayFees);
        }
    }, [gatewayFees]);

    useEffect(() => {
        if (gatewayFees) setSearchedData(gatewayFees);
        if (gatewayFees && props.searchKey) {
            setSearchedData(
                gatewayFees.filter((data) =>
                    data?.name?.toLowerCase().includes(props.searchKey.toLowerCase())
                )
            );
        } else {
            setSearchedData(gatewayFees);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.searchKey, gatewayFees]);
    console.log(searchedData);
    const deleteRate = async (id) => {
        setIsLoading(true);

        const response = await apiCall(`/gateway_fee/${id}`, 'DELETE');
        console.log(response);

        if (response.ok) {
            setMsg(response.message);
            setGatewayFees(gatewayFees.filter((data) => data.id !== id));
        } else {
            setMsg(response.message);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        if (searchedData) {
            setFields(
                searchedData.map((data) => (
                    <tr key={data.id}>
                        <th>
                            <span className="checkbox">
                                <input type="checkbox"></input>
                            </span>
                            <span>#{data.id}</span>
                        </th>
                        <th>
                            <img
                                className="td-image"
                                alt="brand"
                                src={data.payment_method.brand_logo}
                            ></img>
                        </th>
                        <th>{data.payment_method.method_name}</th>
                        <th>{data.name}</th>
                        <th>{data.fee_type}</th>
                        <th>{data.value}</th>
                        <th>{data.currency.code}</th>

                        <th>{new Date(data.createdAt).toLocaleDateString()}</th>
                        <th>
                            <div className="action-btn">
                                <Link
                                    className="edit-icon"
                                    to={`/dashboard/edit/gateway_fee/${data.id}`}
                                    state={data}
                                >
                                    <FiEdit />
                                </Link>
                                <button className="delete-icon" onClick={() => deleteRate(data.id)}>
                                    <RiDeleteBin6Line />
                                </button>
                            </div>
                        </th>
                    </tr>
                ))
            );
        }
    }, [searchedData]);
    console.log(fields);
    return (
        <>
            <div className="exchnage-rate-area">
                <div className="exchnage-rate-table-area">
                    <div className="table-top">
                        <div className="result-show">
                            <span>Results: </span>
                            <select name="" id="">
                                <option value="0">05</option>
                                <option value="0">06</option>
                                <option value="0">07</option>
                                <option value="0">08</option>
                                <option value="0">09</option>
                                <option value="0">10</option>
                            </select>
                        </div>
                        <LinkButton linkBtn={linkBtn} />
                    </div>
                    <div className="exchnage-rate-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <span className="checkbox">
                                            <input type="checkbox"></input>
                                        </span>
                                        <span>Id</span>
                                    </th>
                                    <th>Image</th>
                                    <th>Payment Method</th>
                                    <th>Fee Name</th>
                                    <th>Fee Type</th>
                                    <th>Value</th>
                                    <th>Fee Currency</th>
                                    <th>Date</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            {!isLoading && (
                                <tbody>
                                    {fields ? fields : <NoDataFound data="Gateway Fees" />}
                                </tbody>
                            )}
                        </table>
                        {isLoading && (
                            <>
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default GatewayFee;
