import React, { useEffect, useState } from 'react';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { Shimmer } from 'react-shimmer';
import apiCall from '../../../api/apiCall';
import { getAccessToken } from '../../Login/Auth';
import LinkButton from '../../Shared/LinkButton/LinkButton';
import NoDataFound from '../../Shared/NoDataFound/NoDataFound';

import './Role.css';
const Role = () => {
    const linkBtn = {
        text: 'Add Role',
        btnBG: '#2980FF',
        btnColor: '#ffffff',
        link: '/dashboard/add/role'
    };
    let token = getAccessToken();
    if (token != null) token = token.replace(/['"]+/g, '');
    const [isLoading, setIsLoading] = useState(false);
    const [deleteRoleData, setDeleteRoleData] = useState();
    const [roleData, setRoleData] = useState();
    const [msg, setMsg] = useState();
    useEffect(() => {
        document.title = 'Role';
    });

    const getRoles = async () => {
        setIsLoading(true);

        const response = await apiCall(`/roles`);
        if (response.ok) {
            setRoleData(response.data);
            setMsg('Success');
        } else {
            setMsg(response.msg);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        getRoles();
    }, []);

    const deleteRole = async (id) => {
        setIsLoading(true);

        const response = await apiCall(`/role/${id}`, 'DELETE');
        setMsg(response.msg);
        if (response.ok) {
            setDeleteRoleData(deleteRoleData.filter((data) => data.id !== id));
        } else {
        }

        setIsLoading(false);
    };

    let fields;
    if (roleData) {
        fields = roleData.map((data) => (
            <tr key={data.id}>
                <th>
                    <span className="checkbox">
                        <input type="checkbox"></input>
                    </span>
                    <span>#{data.id}</span>
                </th>
                <th>{data.role_name}</th>
                <th>{new Date(data.createdAt).toLocaleDateString()}</th>
                <th>
                    <div className="action-btn">
                        <Link
                            className="edit-icon"
                            to={`/dashboard/edit/role/${data.id}`}
                            state={data}
                        >
                            <FiEdit />
                        </Link>
                        <button className="delete-icon" onClick={() => deleteRole(data.id)}>
                            <RiDeleteBin6Line />
                        </button>
                    </div>
                </th>
            </tr>
        ));
    }

    return (
        <>
            <div className="role-area">
                <div className="role-table-area">
                    <div className="table-top">
                        <div className="result-show">
                            <span>Total Roles: </span>
                            {!isLoading && <span>{roleData ? roleData.length : ''}</span>}
                        </div>
                        <LinkButton linkBtn={linkBtn} />
                    </div>
                    <div className="role-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <span className="checkbox">
                                            <input type="checkbox"></input>
                                        </span>
                                        <span>Role Id</span>
                                    </th>
                                    <th>Role Name</th>
                                    <th>Creation Date</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            {!isLoading && msg && (
                                <tbody>{fields ? fields : <NoDataFound data="Roles" />}</tbody>
                            )}
                        </table>
                        {isLoading && (
                            <>
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Role;
