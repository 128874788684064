import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Layout from './components/Layout/Layout';
import { checkAccessToken, checkRefreshToken, refreshAuthToken } from './components/Login/Auth';
import Login from './components/Login/Login';
import RecoverPassword from './components/RecoverPassword/RecoverPassword';
import ResetPassword from './components/ResetPassword/ResetPassword';
import Signup from './components/Signup/Signup';

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const loginCheck = async () => {
        setIsLoading(true);
        const isAccess = await checkAccessToken();
        const isRefresh = await checkRefreshToken();
        if (isAccess === true && isRefresh === true) {
            setIsLoggedIn(true);
        } else {
            const isRefreshed = await refreshAuthToken();
            if (isRefreshed) setIsLoggedIn(true);
            else setIsLoggedIn(false);
        }
        setIsLoading(false);
    };
    useEffect(() => {
        loginCheck();
    }, []);
    return (
        <>
            {!isLoggedIn ? (
                <BrowserRouter>
                    <Routes>
                        <Route path="*" element={<Login />} />
                        <Route path="/signup" element={<Signup />} />
                        <Route path="/recover-password" element={<RecoverPassword />} />
                        <Route path="/reset-password/:token" element={<ResetPassword />} />
                    </Routes>
                </BrowserRouter>
            ) : (
                <>
                    <Layout />
                </>
            )}
        </>
    );
}

export default App;
