import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Shimmer } from 'react-shimmer';
import apiCall from '../../../../api/apiCall';

import Button from '../../../Shared/Form/Button/Button';
import Form from '../../../Shared/Form/Form';
import TextInput from '../../../Shared/Form/TextInput/TextInput';
import './EditPolicy.css';
const EditWithdrawPolicy = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState();

    const [policyData, setPolicyData] = useState();

    useEffect(() => {
        document.title = 'Update Withdraw Policy';
    });

    console.log(policyData);

    const updateWithdrawPolicy = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const response = await apiCall(`/policy/${id}`, 'PATCH', policyData);

        if (response.ok) {
            setMsg(response.msg);
        } else {
            setMsg(response.msg);
        }

        setIsLoading(false);
    };
    useEffect(() => {
        if (location.state) setPolicyData(location.state);
    }, [location]);

    return (
        <>
            <div className="edit-exchange-form-area">
                <h3>Edit Withdraw Policy</h3>
                {msg && <div className="msg">{msg}</div>}
                {policyData && (
                    <Form method="post" className={'edit-exchange-form form-area'}>
                        {isLoading && (
                            <>
                                <Shimmer width={'90%'} height={'50px'} />
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <Shimmer width={'90%'} height={'50px'} />
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <Shimmer width={'90%'} height={'50px'} />
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <Shimmer width={'90%'} height={'50px'} />
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                            </>
                        )}
                        {!isLoading && (
                            <>
                                <TextInput
                                    label="Currency Name"
                                    type="text"
                                    value={policyData.currency.code}
                                    disabled
                                />
                                <TextInput
                                    label="Minimum Amount"
                                    type="text"
                                    value={policyData.minimum_amount}
                                    onChange={(e) =>
                                        setPolicyData({
                                            ...policyData,
                                            minimum_amount: e.target.value
                                        })
                                    }
                                />
                                <TextInput
                                    label="Maximum Amount"
                                    type="text"
                                    value={policyData.maximum_amount}
                                    onChange={(e) =>
                                        setPolicyData({
                                            ...policyData,
                                            maximum_amount: e.target.value
                                        })
                                    }
                                />
                                <TextInput
                                    label="Description"
                                    type="text"
                                    value={policyData.description}
                                    onChange={(e) =>
                                        setPolicyData({
                                            ...policyData,
                                            description: e.target.value
                                        })
                                    }
                                />

                                {/* <TextInput
                                    label="Exchange Rate"
                                    type="text"
                                    value={exchnageData.exchange_rate || ''}
                                    onChange={(e) =>
                                        setExchangeData({
                                            ...exchnageData,
                                            exchange_rate: e.target.value
                                        })
                                    }
                                /> */}
                            </>
                        )}
                        <div className="form-button">
                            <Button className={'btn-add'} onClick={updateWithdrawPolicy}>
                                Update
                            </Button>
                            <Button onClick={() => navigate(-1)} className={'btn-close'}>
                                Cancel
                            </Button>
                        </div>
                    </Form>
                )}
            </div>
        </>
    );
};

export default EditWithdrawPolicy;
