import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Shimmer } from 'react-shimmer';
import apiCall from '../../../../api/apiCall';
import Button from '../../../Shared/Form/Button/Button';
import Form from '../../../Shared/Form/Form';
import TextInput from '../../../Shared/Form/TextInput/TextInput';
import './AddGatewayFee.css';
const AddGatewayFee = (props) => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState();
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
    const [selectedFeeCurrency, setSelectedFeeCurrency] = useState();
    const [feeName, setFeeName] = useState();
    const [value, setValue] = useState();
    const [feeType, setFeeType] = useState('Fixed');
    const [currencyData, setCurrencyData] = useState([]);
    console.log(selectedFeeCurrency, selectedPaymentMethod, feeName, value, feeType);
    useEffect(() => {
        document.title = 'Add Gateway Fee';
    });

    const addGatewayFee = async (e) => {
        e.preventDefault();

        setIsLoading(true);

        const response = await apiCall(`/gateway_fee`, 'POST', {
            fee_currency: selectedFeeCurrency,
            payment_method_id: selectedPaymentMethod,
            name: feeName,
            value,
            fee_type: feeType
        });
        console.log(response);
        setMsg(response.msg);
        if (response.ok) {
        } else {
        }

        setIsLoading(false);
    };

    const getPaymentMethods = async () => {
        setIsLoading(true);

        const response = await apiCall(`/payment_methods`);

        if (response.ok) {
            setPaymentMethods(response.data);
            setMsg('Success');
        } else {
            setMsg(response.msg);
        }

        setIsLoading(false);
    };
    const getCurrencies = async () => {
        setIsLoading(true);

        const response = await apiCall(`/currencies`);

        if (response.ok) {
            setCurrencyData(response.data);
            setMsg('Success');
        } else {
            setMsg(response.msg);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        if (paymentMethods.length > 0) {
            setSelectedPaymentMethod(paymentMethods[0].id.toString());
        }
        if (currencyData.length > 0) {
            setSelectedFeeCurrency(currencyData[0].id);
        }
    }, [paymentMethods, currencyData]);

    useEffect(() => {
        getPaymentMethods();
        getCurrencies();
    }, []);

    return (
        <>
            <div className="add-exchange-form-area">
                <h3>Add Gateway Fee </h3>
                {msg && <div className="msg">{msg}</div>}
                <Form method="post" className={'add-exchange-form form-area'}>
                    {isLoading && (
                        <>
                            <Shimmer width={'90%'} height={'50px'} />
                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                            <Shimmer width={'90%'} height={'50px'} />
                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                        </>
                    )}
                    {!isLoading && (
                        <>
                            <div className="form-field">
                                <label>Payment Method</label>
                                <select
                                    className="select-box"
                                    onChange={(e) => setSelectedPaymentMethod(e.target.value)}
                                    value={selectedPaymentMethod}
                                >
                                    {paymentMethods &&
                                        paymentMethods.map((data) => (
                                            <option key={data.id} value={data.id}>
                                                {data.method_name}
                                            </option>
                                        ))}
                                </select>
                            </div>
                            <div className="form-field">
                                <label>Fee Currency</label>
                                <select
                                    className="select-box"
                                    onChange={(e) => setSelectedFeeCurrency(e.target.value)}
                                    value={selectedFeeCurrency}
                                >
                                    {currencyData &&
                                        currencyData.map((data) => (
                                            <option key={data.id} value={data.id}>
                                                {data.currency_name}
                                            </option>
                                        ))}
                                </select>
                            </div>
                            <div className="form-field">
                                <label>Fee Type</label>
                                <select
                                    className="select-box"
                                    onChange={(e) => setFeeType(e.target.value)}
                                    value={feeType}
                                >
                                    <option key={1} value={'Percentage'}>
                                        Percentage
                                    </option>
                                    <option key={2} value={'Fixed'}>
                                        Fixed
                                    </option>
                                </select>
                            </div>
                            <TextInput
                                label="Value"
                                type="text"
                                required
                                onChange={(e) => setValue(e.target.value)}
                            />
                            <TextInput
                                label="Fee Name (Optional)"
                                type="text"
                                onChange={(e) => setFeeName(e.target.value)}
                            />
                        </>
                    )}
                    <div className="form-button">
                        <Button
                            onClick={addGatewayFee}
                            // disabled={!(from_currency && to_currency && exchange_rate)}
                            className={'btn-add'}
                        >
                            Add
                        </Button>
                        <Button onClick={() => navigate(-1)} className={'btn-close'}>
                            Cancel
                        </Button>
                    </div>
                </Form>
            </div>
        </>
    );
};

export default AddGatewayFee;
