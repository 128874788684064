import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Shimmer } from 'react-shimmer';
import apiCall from '../../../../api/apiCall';
import Button from '../../../Shared/Form/Button/Button';
import Form from '../../../Shared/Form/Form';
import TextInput from '../../../Shared/Form/TextInput/TextInput';
import './AddWithdrawPolicy.css';
const AddWithdrawPolicy = (props) => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState();
    const [currenciesJSX, setCurrenciesJSX] = useState();
    const [currencyData, setCurrencyData] = useState();
    const [policyData, setPolicyData] = useState({
        minimum_amount: '',
        maximum_amount: '',
        description: '',
        currency_id: '',
        type: 'WITHDRAW'
    });

    useEffect(() => {
        document.title = 'Add Withdraw Policy';
    });

    const getCurrencies = async () => {
        setIsLoading(true);

        const response = await apiCall(`/currencies`);

        if (response.ok) {
            setCurrencyData(response.data);
            setMsg('Success');
        } else {
            setMsg(response.msg);
        }

        setIsLoading(false);
    };

    const addWithdrawPolicy = async (e) => {
        e.preventDefault();

        setIsLoading(true);

        const response = await apiCall(`/policy`, 'POST', policyData);

        setMsg(response.msg);
        if (response.ok) {
            // setMsg('Success');
        } else {
        }

        setIsLoading(false);
    };

    useEffect(() => {
        getCurrencies();
    }, []);

    useEffect(() => {
        if (currencyData && currencyData.length > 0) {
            setPolicyData({ ...policyData, currency_id: currencyData[0].id });
            setCurrenciesJSX(
                currencyData.map((data) => (
                    <option key={data.id} value={data.id}>
                        {data.currency_name}
                    </option>
                ))
            );
        }
    }, [currencyData]);

    return (
        <>
            <div className="add-exchange-form-area">
                <h3>Add Withdraw Policy</h3>
                {msg && <div className="msg">{msg}</div>}
                <Form method="post" className={'add-exchange-form form-area'}>
                    {isLoading && (
                        <>
                            <Shimmer width={'90%'} height={'50px'} />
                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                            <Shimmer width={'90%'} height={'50px'} />
                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                        </>
                    )}
                    {!isLoading && (
                        <>
                            <div className="form-field">
                                <label>Currency</label>
                                <select
                                    className="select-box"
                                    onChange={(e) =>
                                        setPolicyData({
                                            ...policyData,
                                            currency_id: e.target.value
                                        })
                                    }
                                    value={policyData.currency_id}
                                >
                                    {currenciesJSX}
                                </select>
                            </div>
                            <TextInput
                                label="Minimum Amount"
                                type="text"
                                required
                                onChange={(e) =>
                                    setPolicyData({ ...policyData, minimum_amount: e.target.value })
                                }
                                value={policyData.minimum_amount}
                            />
                            <TextInput
                                label="Maximum Amount"
                                type="text"
                                required
                                onChange={(e) =>
                                    setPolicyData({ ...policyData, maximum_amount: e.target.value })
                                }
                                value={policyData.maximum_amount}
                            />
                            <TextInput
                                label="Description"
                                type="text"
                                onChange={(e) =>
                                    setPolicyData({ ...policyData, description: e.target.value })
                                }
                                value={policyData.description}
                            />
                        </>
                    )}
                    <div className="form-button">
                        <Button onClick={addWithdrawPolicy} className={'btn-add'}>
                            Add
                        </Button>
                        <Button onClick={() => navigate(-1)} className={'btn-close'}>
                            Cancel
                        </Button>
                    </div>
                </Form>
            </div>
        </>
    );
};

export default AddWithdrawPolicy;
