import React, { useEffect } from 'react';
import { getAccessToken } from '../../Login/Auth';

const Overview = () => {
    let token = getAccessToken();
    useEffect(() => {
        document.title = 'Overview';
    });
    let today = new Date();
    if (token != null) token = token.replace(/['"]+/g, '');
    // const formatDate = today.toISOString().split('T')[0];
    // if (token != null) token = token.replace(/['"]+/g, '');

    return (
        <div style={{ textAlign: 'center', width: '90%' }}>
            <div>Upcoming Feature</div>
        </div>
    );
};
export default Overview;
