import { FiUser } from 'react-icons/fi';
import { HiOutlineLockClosed } from 'react-icons/hi';
import { MdOutlineNotificationsActive } from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import './SettingsSidebar.css';

function SettingsSidebar() {
    return (
        <div className="settings-sidebar">
            <div className="sidebar-main">
                <div className="sidebar-menu-area">
                    <div className="sidebar-menu">
                        <NavLink
                            to="profile"
                            className={({ isActive }) => (isActive ? 'active-class' : 'no-active')}
                        >
                            <span className="sidebar-icon">
                                <FiUser />
                            </span>
                            <span className="sidbar-title">General</span>
                        </NavLink>
                    </div>
                </div>
                <div className="sidebar-menu-area">
                    <div className="sidebar-menu">
                        <NavLink
                            to="change-password"
                            className={({ isActive }) => (isActive ? 'active-class' : 'no-active')}
                        >
                            <span className="sidebar-icon">
                                <HiOutlineLockClosed />
                            </span>
                            <span className="sidbar-title">Change Password</span>
                        </NavLink>
                    </div>
                </div>
                <div className="sidebar-menu-area">
                    <div className="sidebar-menu">
                        <NavLink
                            to="notification"
                            className={({ isActive }) => (isActive ? 'active-class' : 'no-active')}
                        >
                            <span className="sidebar-icon">
                                <MdOutlineNotificationsActive />
                            </span>
                            <span className="sidbar-title">Notificaiton</span>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SettingsSidebar;
