import { useEffect, useState } from 'react';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Image, Shimmer } from 'react-shimmer';
import apiCall from '../../../../api/apiCall';
import Button from '../../../Shared/Form/Button/Button';
import Form from '../../../Shared/Form/Form';
import TextInput from '../../../Shared/Form/TextInput/TextInput';
import './EditDocument.css';
const EditDocument = ({ ...props }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState();
    const [documentData, setDocumentData] = useState();
    const [allDocumentsData, setAllDocumentData] = useState();

    useEffect(() => {
        document.title = 'Update Document';
    });

    const gotoPrevious = (id) => {
        setMsg('');
        const i = allDocumentsData.indexOf(documentData);

        if (i === 0) {
            setMsg('No Previous Document');
            return;
        }
        // setDocumentData(allDocumentsData[i - 1]);
        navigate('/dashboard/edit/document/' + allDocumentsData[i - 1].id, {
            state: { data: allDocumentsData[i - 1], allData: allDocumentsData }
        });
    };
    const gotoNext = (id) => {
        setMsg('');
        const i = allDocumentsData.indexOf(documentData);
        if (i === allDocumentsData.length - 1) {
            setMsg('No Next Document');
            return;
        }

        navigate('/dashboard/edit/document/' + allDocumentsData[i + 1].id, {
            state: { data: allDocumentsData[i + 1], allData: allDocumentsData }
        });
    };

    const getDocument = async (e) => {
        setIsLoading(true);
        const response = await apiCall(`/document/${id}`);
        if (response.ok) {
            setDocumentData(response.data);
            setMsg('Success');
        } else {
            setMsg(response.message);
        }
        setIsLoading(false);
    };

    const updateDocument = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const response = await apiCall(`/document/${documentData.id}`, 'PATCH', {
            status: documentData.status
        });
        if (response.ok) {
            setMsg(response.msg);
            setAllDocumentData(() =>
                allDocumentsData?.map((data, i) => {
                    if (data.id === documentData.id) return documentData;
                    return data;
                })
            );
            // window.history.replaceState({}, document.title);
        } else {
            setMsg(response.msg);
        }
        setIsLoading(false);
    };
    useEffect(() => {
        console.log(location);
        if (location.state) {
            setDocumentData(location.state.data);
            setAllDocumentData(location.state.allData);
        } else getDocument();
    }, [location.state]);

    return (
        <>
            {allDocumentsData?.length > 0 && (
                <>
                    <AiOutlineArrowLeft
                        onClick={() => gotoPrevious(documentData?.id)}
                        className="prev-next-icon-left"
                    />
                    <AiOutlineArrowRight
                        onClick={() => gotoNext(documentData?.id)}
                        className="prev-next-icon-right"
                    />
                </>
            )}
            <div className="edit-document-form-area">
                {msg && <div className="msg">{msg}</div>}
                <h3>Update Document</h3>

                {documentData && (
                    <Form method="post" className={'edit-document-form form-area'}>
                        {isLoading && (
                            <>
                                <Shimmer width={'90%'} height={'50px'} />
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <Shimmer width={'90%'} height={'50px'} />
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                            </>
                        )}

                        {!isLoading && (
                            <>
                                {documentData.document_type.option === 'NID' ? (
                                    <>
                                        <a
                                            className="fileImage-large"
                                            target="_blank"
                                            href={`${process.env.REACT_APP_UPLOAD_URL}/image/${
                                                documentData.file_key.split(',')[0]
                                            }`}
                                            rel="noreferrer"
                                        >
                                            <Image
                                                className="fileImage-large"
                                                alt="file_key"
                                                src={
                                                    documentData && documentData.file_key
                                                        ? `${
                                                              process.env.REACT_APP_UPLOAD_URL
                                                          }/image/${
                                                              documentData.file_key.split(',')[0]
                                                          }`
                                                        : 'https://via.placeholder.com/150'
                                                }
                                                fallback={
                                                    <Shimmer width={'250px'} height={'250px'} />
                                                }
                                            />
                                        </a>
                                        <a
                                            className="fileImage-large"
                                            target="_blank"
                                            href={`${process.env.REACT_APP_UPLOAD_URL}/image/${
                                                documentData.file_key.split(',')[1]
                                            }`}
                                            rel="noreferrer"
                                        >
                                            <Image
                                                alt="file_key"
                                                src={
                                                    documentData && documentData.file_key
                                                        ? `${
                                                              process.env.REACT_APP_UPLOAD_URL
                                                          }/image/${
                                                              documentData.file_key.split(',')[1]
                                                          }`
                                                        : 'https://via.placeholder.com/150'
                                                }
                                                fallback={
                                                    <Shimmer width={'250px'} height={'250px'} />
                                                }
                                            />
                                        </a>
                                    </>
                                ) : (
                                    <>
                                        <a
                                            className="fileImage-large"
                                            target="_blank"
                                            href={`${process.env.REACT_APP_UPLOAD_URL}/image/${documentData.file_key}`}
                                            rel="noreferrer"
                                        >
                                            <Image
                                                alt="file_key"
                                                src={
                                                    documentData && documentData.file_key
                                                        ? `${process.env.REACT_APP_UPLOAD_URL}/image/${documentData.file_key}`
                                                        : 'https://via.placeholder.com/150'
                                                }
                                                fallback={
                                                    <Shimmer width={'250px'} height={'250px'} />
                                                }
                                            />
                                        </a>
                                    </>
                                )}
                                <br></br>
                                <br></br>
                                <br></br>
                                <TextInput
                                    label="Document Type"
                                    type="text"
                                    required
                                    value={documentData.document_type.option}
                                    disabled
                                />
                                {documentData && documentData.status === 'Pending' ? (
                                    <div className="form-field">
                                        <label>Status</label>
                                        <select
                                            className="select-box"
                                            onChange={(e) =>
                                                setDocumentData({
                                                    ...documentData,
                                                    status: e.target.value
                                                })
                                            }
                                        >
                                            <option key="Select" value="Select">
                                                Select
                                            </option>
                                            <option key="Approved" value="Approved">
                                                Approved
                                            </option>
                                            <option key="Rejected" value="Rejected">
                                                Rejected
                                            </option>
                                        </select>
                                    </div>
                                ) : (
                                    <TextInput
                                        label="Withdraw Status"
                                        type="text"
                                        required
                                        value={documentData.status}
                                        disabled
                                    />
                                )}
                                <TextInput
                                    label="Full Name"
                                    type="text"
                                    value={documentData.user.fullName}
                                    disabled
                                />
                            </>
                        )}
                        <div className="form-button">
                            <Button className={'btn-add'} onClick={updateDocument}>
                                Update
                            </Button>
                            <Button onClick={() => navigate(-1)} className={'btn-close'}>
                                Cancel
                            </Button>
                        </div>
                    </Form>
                )}
            </div>
        </>
    );
};

export default EditDocument;
