import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import apiCall from '../../../../api/apiCall';
import Loading from '../../../Loading/Loading';
import { getAccessToken } from '../../../Login/Auth';
import Button from '../../../Shared/Form/Button/Button';
import Form from '../../../Shared/Form/Form';
import TextArea from '../../../Shared/Form/TextArea/TextArea';
import TextInput from '../../../Shared/Form/TextInput/TextInput';
import SettingsTitle from '../../../Shared/SettingsTitle/SettingsTitle';

function Notification() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const token = getAccessToken();
    const [msg, setMsg] = useState();
    const [notificationData, setNotificationData] = useState({
        title: '',
        body: ''
    });
    useEffect(() => {
        document.title = 'Notification';
    });
    const addNotification = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const response = await apiCall(`/notification`, 'POST', notificationData);
        setMsg(response.msg);
        if (response.ok) {
            setNotificationData({
                title: '',
                body: ''
            });
        } else {
        }

        setIsLoading(false);
    };
    return (
        <>
            <div>
                <SettingsTitle title={'Notification'} />
                {isLoading && <Loading />}
                <div className="view-profile-area">
                    <div className="view-profile">
                        {msg && <p>{msg}</p>}
                        <div className="view-profile">
                            <Form onSubmit={addNotification} className={'settings-form-area'}>
                                <TextInput
                                    type="text"
                                    label="Notification Title"
                                    value={notificationData.title}
                                    required
                                    onChange={(e) => {
                                        setNotificationData({
                                            ...notificationData,
                                            title: e.target.value
                                        });
                                    }}
                                />
                                <br></br>
                                <TextArea
                                    type="text"
                                    label="Notification Body"
                                    value={notificationData.body}
                                    required
                                    onChange={(e) => {
                                        setNotificationData({
                                            ...notificationData,
                                            body: e.target.value
                                        });
                                    }}
                                />

                                <br></br>
                                <div className="form-button">
                                    <Button
                                        type="submit"
                                        // onClick={changePassword}
                                        className={'btn-add'}
                                    >
                                        Save
                                    </Button>
                                    <Button onClick={() => navigate(-1)} className={'btn-close'}>
                                        Cancel
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Notification;
