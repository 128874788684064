import moment from 'moment';
import { useEffect, useState } from 'react';
import { FiEdit } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { Shimmer } from 'react-shimmer';
import apiCall from '../../../api/apiCall';
import { getAccessToken } from '../../Login/Auth';
import LinkButton from '../../Shared/LinkButton/LinkButton';
import NoDataFound from '../../Shared/NoDataFound/NoDataFound';
import './Withdraw.css';
const Withdraw = () => {
    const linkBtn = {
        text: 'Add New',
        btnBG: '#2980FF',
        btnColor: '#ffffff',
        link: '/dashboard/promo'
    };
    let token = getAccessToken();
    if (token != null) token = token.replace(/['"]+/g, '');
    const [isLoading, setIsLoading] = useState(false);
    const [statusName, setStatusName] = useState('Hold');
    const [filterData, setFilteredData] = useState();
    const [withdrawData, setWithdrawData] = useState();
    const [paginationDataFromAPI, setPaginationDataFromAPI] = useState();
    const [paginationData, setPaginationData] = useState({
        limit: 100,
        offset: 0
    });
    const [offsetOptions, setOffsetOptions] = useState([]);
    const [msg, setMsg] = useState();
    useEffect(() => {
        document.title = 'Withdraw';
    });

    const getAllWithdraws = async () => {
        setIsLoading(true);

        const response = await apiCall(
            `/withdraws?limit=${paginationData.limit}&offset=${paginationData.offset}`
        );

        if (response.ok) {
            setWithdrawData(response.data.data);
            setPaginationDataFromAPI({
                total: response.data.totalPages,
                current: response.data.currentPage
            });
            setMsg('Success');
        } else {
            setMsg(response.msg);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        getAllWithdraws();
    }, [paginationData]);

    useEffect(() => {
        if (withdrawData && statusName !== 'All') {
            const data = withdrawData.filter((value) => value.status === statusName);
            setFilteredData(data);
        } else {
            setFilteredData(withdrawData);
        }
    }, [statusName, withdrawData]);

    let fields;
    if (filterData) {
        fields = filterData.map((data) => (
            <tr key={data.id}>
                <th>
                    <span className="checkbox">
                        <input type="checkbox"></input>
                    </span>
                    <span>#{data.withdraw_id}</span>
                </th>
                <th>{data.status}</th>
                <th>{data.withdraw_amount.toFixed(2)}</th>
                <th>{data.received_amount.toFixed(2)}</th>
                <th>{data.withdraw_fee}</th>
                <th>{data.exchange_rate}</th>
                <th>{data.bank_name}</th>
                <th>{data.account_name}</th>
                <th>{data.account_number}</th>
                <th>{moment(new Date(data.withdraw_time), 'MMDDYYYY').fromNow()}</th>
                <th>
                    <div className="action-btn">
                        <Link
                            className="edit-icon"
                            to={`/dashboard/edit/withdraw/${data.withdraw_id}`}
                            state={{ data, allData: withdrawData }}
                        >
                            <FiEdit />
                        </Link>
                    </div>
                </th>
            </tr>
        ));
    }

    useEffect(() => {
        if (
            paginationDataFromAPI &&
            paginationDataFromAPI.total &&
            paginationDataFromAPI.total > 0
        ) {
            let options = [];
            for (let i = 0; i < paginationDataFromAPI.total; i++) {
                options.push(
                    <option key={i} value={i}>
                        {i + 1}
                    </option>
                );
            }
            setOffsetOptions(options);
        }
    }, [paginationDataFromAPI]);

    return (
        <>
            <div className="withdraw-area">
                <div className="withdraw-table-area">
                    <div className="table-top">
                        <div className="withdraw-result-show">
                            <span>Status: </span>
                            <select
                                defaultValue={statusName}
                                onChange={(e) => setStatusName(e.target.value)}
                            >
                                <option key="All" value="All">
                                    All
                                </option>
                                <option key="Hold" value="Hold">
                                    Hold
                                </option>
                                <option key="Deposited" value="Deposited">
                                    Deposited
                                </option>
                                <option key="Rejected" value="Rejected">
                                    Rejected
                                </option>
                            </select>
                        </div>
                        <div className="withdraw-result-show">
                            <span>Limit: </span>
                            <select
                                defaultValue={paginationData.limit}
                                onChange={(e) =>
                                    setPaginationData({ offset: 0, limit: e.target.value })
                                }
                            >
                                <option key="10" value={10}>
                                    10
                                </option>
                                <option key="25" value={25}>
                                    25
                                </option>
                                <option key="50" value={50}>
                                    50
                                </option>
                                <option key="100" value={100}>
                                    100
                                </option>
                            </select>
                        </div>
                        <div className="withdraw-result-show">
                            <span>Page: </span>
                            <select
                                defaultValue={0}
                                onChange={(e) =>
                                    setPaginationData({ ...paginationData, offset: e.target.value })
                                }
                            >
                                {offsetOptions}
                            </select>
                        </div>
                        <LinkButton linkBtn={linkBtn} />
                    </div>
                    <div className="withdraw-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <span className="checkbox">
                                            <input type="checkbox"></input>
                                        </span>
                                        <span>Withdraw Code</span>
                                    </th>
                                    <th>Withdraw Status</th>
                                    <th>Withdraw Amount (USD)</th>
                                    <th>Receive Amount (BDT)</th>
                                    <th>Withdraw Fee</th>
                                    <th>Exchange Rate</th>
                                    <th>Bank Name</th>
                                    <th>Bank Account Name</th>
                                    <th>Bank Account Number</th>
                                    <th>Withdraw Time</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            {!isLoading && msg && (
                                <tbody>{fields ? fields : <NoDataFound data="Withdraws" />}</tbody>
                            )}
                        </table>
                        {isLoading && (
                            <>
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Withdraw;
