import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Shimmer } from 'react-shimmer';
import apiCall from '../../../../api/apiCall';
import Button from '../../../Shared/Form/Button/Button';
import Form from '../../../Shared/Form/Form';
import TextInput from '../../../Shared/Form/TextInput/TextInput';
import './AddFees.css';
const AddFees = (props) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState();
    const [feesData, setFeesData] = useState({
        name: '',
        value: '',
        description: '',
        withdraw_time: 0
    });
    const [currencyData, setCurrencyData] = useState();
    const [from_currency, setFromCurrency] = useState();
    const [to_currency, setToCurrency] = useState();
    const [fee_currency, setFeeCurrency] = useState();
    const [currenciesJSX, setCurrenciesJSX] = useState();

    useEffect(() => {
        document.title = 'Add Withdraw Fee';
    });

    const addWithdrawFee = async (e) => {
        e.preventDefault();
        if (from_currency === to_currency) {
            setMsg('From Currency and To Currency must be different');
            return;
        }

        setIsLoading(true);
        // setFeesData((feesData)=>{ ...feesData, from_currency, to_currency, fee_currency});

        const response = await apiCall(`/withdraw_fees`, 'POST', {
            ...feesData,
            from_currency,
            to_currency,
            fee_currency
        });

        setMsg(response.msg);
        if (response.ok) {
            // setMsg('Success');
        } else {
        }

        setIsLoading(false);
    };

    const getCurrencies = async () => {
        setIsLoading(true);

        const response = await apiCall(`/currencies`);

        if (response.ok) {
            setCurrencyData(response.data);
            setMsg('Success');
        } else {
            setMsg(response.msg);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        getCurrencies();
    }, []);
    useEffect(() => {
        if (currencyData && currencyData.length > 0) {
            setFromCurrency(2);
            setToCurrency(1);
            setFeeCurrency(2);
            setCurrenciesJSX(
                currencyData.map((data) => (
                    <option key={data.id} value={data.id}>
                        {data.currency_name}
                    </option>
                ))
            );
        }
    }, [currencyData]);

    console.log(from_currency, to_currency, fee_currency);

    return (
        <>
            <div className="add-exchange-form-area">
                <h3>Add Withdraw Fee</h3>
                {msg && <div className="msg">{msg}</div>}
                <Form method="post" className={'add-exchange-form form-area'}>
                    {isLoading && (
                        <>
                            <Shimmer width={'90%'} height={'50px'} />
                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                            <Shimmer width={'90%'} height={'50px'} />
                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                        </>
                    )}
                    {!isLoading && (
                        <>
                            <div className="form-field">
                                <label>From Currency</label>
                                <select
                                    className="select-box"
                                    onChange={(e) => setFromCurrency(e.target.value)}
                                    value={from_currency}
                                >
                                    {currenciesJSX}
                                </select>
                            </div>
                            <div className="form-field">
                                <label>To Currency</label>
                                <select
                                    className="select-box"
                                    onChange={(e) => setToCurrency(e.target.value)}
                                    value={to_currency}
                                >
                                    {currenciesJSX}
                                </select>
                            </div>

                            <TextInput
                                label="Name"
                                type="text"
                                required
                                onChange={(e) => setFeesData({ ...feesData, name: e.target.value })}
                                value={feesData.name}
                            />
                            <TextInput
                                label="Value"
                                type="text"
                                required
                                onChange={(e) =>
                                    setFeesData({ ...feesData, value: e.target.value })
                                }
                                value={feesData.value}
                            />
                            <div className="form-field">
                                <label>Fee Currency</label>
                                <select
                                    className="select-box"
                                    onChange={(e) => setFeeCurrency(e.target.value)}
                                    value={fee_currency}
                                >
                                    {currenciesJSX}
                                </select>
                            </div>
                            <TextInput
                                label="Withdraw Time(Min)"
                                type="number"
                                required
                                onChange={(e) =>
                                    setFeesData({ ...feesData, withdraw_time: e.target.value })
                                }
                                value={feesData.withdraw_time}
                            />
                            <TextInput
                                label="Description"
                                type="text"
                                onChange={(e) =>
                                    setFeesData({ ...feesData, description: e.target.value })
                                }
                                value={feesData.description}
                            />
                        </>
                    )}
                    <div className="form-button">
                        <Button onClick={addWithdrawFee} className={'btn-add'}>
                            Add
                        </Button>
                        <Button onClick={() => navigate(-1)} className={'btn-close'}>
                            Cancel
                        </Button>
                    </div>
                </Form>
            </div>
        </>
    );
};

export default AddFees;
