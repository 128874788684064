import moment from 'moment/moment';
import { useEffect, useState } from 'react';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { Shimmer } from 'react-shimmer';
import apiCall from '../../../api/apiCall';
import LinkButton from '../../Shared/LinkButton/LinkButton';
import NoDataFound from '../../Shared/NoDataFound/NoDataFound';
import './Fees.css';
const Fees = () => {
    const linkBtn = {
        text: 'Add New',
        btnBG: '#2980FF',
        btnColor: '#ffffff',
        link: '/dashboard/add/fees'
    };

    const [isLoading, setIsLoading] = useState(false);

    const [fields, setFields] = useState();

    const [feesData, setFeesData] = useState();

    const [msg, setMsg] = useState();
    useEffect(() => {
        document.title = 'Fees';
    });

    const deleteFees = async (id) => {
        setIsLoading(true);

        const response = await apiCall(`/withdraw_fees/${id}`, 'DELETE');

        if (response.ok) {
            setMsg(response.msg);
            setFeesData(feesData.filter((data) => data.id !== id));
        } else {
            setMsg(response.msg);
        }

        setIsLoading(false);
    };

    const getAllFees = async () => {
        setIsLoading(true);

        const response = await apiCall(`/withdraw_fees`);

        setMsg(response.msg);

        if (response.ok) {
            setFeesData(response.data);
        } else {
        }

        setIsLoading(false);
    };

    useEffect(() => {
        getAllFees();
    }, []);

    console.log(feesData);

    useEffect(() => {
        if (!feesData) return;
        setFields(
            feesData.map((data) => (
                <tr key={data.id}>
                    <th>
                        <span className="checkbox">
                            <input type="checkbox"></input>
                        </span>
                        <span>#{data.id}</span>
                    </th>
                    <th> {data.currency_from_currency.code} </th>
                    <th> {data.currency_to_currency.code} </th>
                    <th>{data.name}</th>
                    <th>
                        {data.value.toFixed(2)} ({data.currency_fee_currency.code})
                    </th>
                    <th>{data.withdraw_time}</th>
                    <th>{data.description}</th>
                    <th>{moment(new Date(data.updatedAt), 'MMDDYYYY').fromNow()}</th>
                    <th>{moment(new Date(data.createdAt), 'MMDDYYYY').fromNow()}</th>

                    <th>
                        <div className="action-btn">
                            <Link
                                className="edit-icon"
                                to={`/dashboard/edit/fees/${data.id}`}
                                state={data}
                            >
                                <FiEdit />
                            </Link>
                            <button className="delete-icon" onClick={() => deleteFees(data.id)}>
                                <RiDeleteBin6Line />
                            </button>
                        </div>
                    </th>
                </tr>
            ))
        );
    }, [feesData]);

    return (
        <>
            <div className="withdraw-area">
                <div className="withdraw-table-area">
                    <div className="table-top">
                        <div className="withdraw-result-show"></div>

                        {msg && <p>{msg}</p>}
                        <LinkButton linkBtn={linkBtn} />
                    </div>
                    <div className="withdraw-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <span className="checkbox">
                                            <input type="checkbox"></input>
                                        </span>
                                        <span>ID</span>
                                    </th>
                                    <th>From</th>
                                    <th>To</th>
                                    <th>Name</th>
                                    <th>fee</th>
                                    <th>Withdraw Time(Min)</th>
                                    <th>Description</th>
                                    <th>Update Time</th>
                                    <th>Created Time</th>

                                    <th>Action</th>
                                </tr>
                            </thead>
                            {!isLoading && msg && (
                                <tbody>{fields ? fields : <NoDataFound data="Fees" />}</tbody>
                            )}
                        </table>
                        {isLoading && (
                            <>
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Fees;
