import moment from 'moment/moment';
import { useEffect, useState } from 'react';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { Shimmer } from 'react-shimmer';
import apiCall from '../../../api/apiCall';
import { getAccessToken } from '../../Login/Auth';
import LinkButton from '../../Shared/LinkButton/LinkButton';
import NoDataFound from '../../Shared/NoDataFound/NoDataFound';
import './WithdrawPolicy.css';
const WithdrawPolicy = () => {
    const linkBtn = {
        text: 'Add New',
        btnBG: '#2980FF',
        btnColor: '#ffffff',
        link: '/dashboard/add/policy'
    };
    let token = getAccessToken();
    if (token != null) token = token.replace(/['"]+/g, '');
    const [isLoading, setIsLoading] = useState(false);

    const [fields, setFields] = useState();

    const [policyData, setPolicyData] = useState();

    const [msg, setMsg] = useState();
    const deleteWithdrawPolicy = async (id) => {
        setIsLoading(true);

        const response = await apiCall(`/policy/${id}`, 'DELETE');

        if (response.ok) {
            setMsg(response.msg);
            setPolicyData(policyData.filter((data) => data.id !== id));
        } else {
            setMsg(response.msg);
        }

        setIsLoading(false);
    };
    useEffect(() => {
        document.title = 'Withdraw Policies';
    });

    const getAllWithdrawPolicy = async () => {
        setIsLoading(true);

        const response = await apiCall(`/policy?type=WITHDRAW&currency=`);
        console.log(response.data);

        setMsg(response.msg);

        if (response.ok) {
            setPolicyData(response.data);
        } else {
        }

        setIsLoading(false);
    };

    useEffect(() => {
        getAllWithdrawPolicy();
    }, []);

    useEffect(() => {
        if (!policyData) return;
        setFields(
            policyData.map((data) => (
                <tr key={data.id}>
                    <th>
                        <span className="checkbox">
                            <input type="checkbox"></input>
                        </span>
                        <span>#{data.id}</span>
                    </th>
                    <th>{data.type}</th>
                    <th>{data.currency.code}</th>
                    <th>{data.minimum_amount.toFixed(2)}</th>
                    <th>{data.maximum_amount.toFixed(2)}</th>
                    <th>{data.description}</th>
                    <th>{moment(new Date(data.updatedAt), 'MMDDYYYY').fromNow()}</th>
                    <th>{moment(new Date(data.createdAt), 'MMDDYYYY').fromNow()}</th>

                    <th>
                        <div className="action-btn">
                            <Link
                                className="edit-icon"
                                to={`/dashboard/edit/policy/${data.id}`}
                                state={data}
                            >
                                <FiEdit />
                            </Link>
                            <button
                                className="delete-icon"
                                onClick={() => deleteWithdrawPolicy(data.id)}
                            >
                                <RiDeleteBin6Line />
                            </button>
                        </div>
                    </th>
                </tr>
            ))
        );
    }, [policyData]);

    return (
        <>
            <div className="withdraw-area">
                <div className="withdraw-table-area">
                    <div className="table-top">
                        <div className="withdraw-result-show"></div>
                        {msg && <p>{msg}</p>}
                        <LinkButton linkBtn={linkBtn} />
                    </div>
                    <div className="withdraw-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <span className="checkbox">
                                            <input type="checkbox"></input>
                                        </span>
                                        <span>ID</span>
                                    </th>
                                    <th>Type</th>
                                    <th>Currency</th>
                                    <th>Maximum</th>
                                    <th>Minimum</th>
                                    <th>Description</th>
                                    <th>Update Time</th>
                                    <th>Created Time</th>

                                    <th>Action</th>
                                </tr>
                            </thead>
                            {!isLoading && msg && (
                                <tbody>
                                    {fields ? fields : <NoDataFound data="Withdraw Policies" />}
                                </tbody>
                            )}
                        </table>
                        {isLoading && (
                            <>
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default WithdrawPolicy;
