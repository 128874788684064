import { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import { useNavigate } from 'react-router-dom';
import { Shimmer } from 'react-shimmer';
import apiCall from '../../../../api/apiCall';
import { getAccessToken } from '../../../Login/Auth';
import Button from '../../../Shared/Form/Button/Button';
import Form from '../../../Shared/Form/Form';
import TextInput from '../../../Shared/Form/TextInput/TextInput';
import SettingsTitle from '../../../Shared/SettingsTitle/SettingsTitle';
import './Profile.css';
const Profile = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [profileData, setProfileData] = useState();
    const [adminId, setAdminId] = useState();
    const [msg, setMsg] = useState();
    const token = getAccessToken();
    useEffect(() => {
        document.title = 'Profile';
    }, []);

    const getLoggedInAdmin = async (id) => {
        setIsLoading(true);

        const response = await apiCall(`/profile/${id}`);
        setMsg(response.msg);
        if (response.ok) {
            setProfileData(response.data.admin);
        } else {
        }

        setIsLoading(false);
    };
    useEffect(() => {
        const id = JSON.parse(atob(token.split('.')[1]));
        setAdminId(id.sub);
        getLoggedInAdmin(id.sub);
    }, []);

    const updateAdminProfile = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const response = await apiCall(`/profile/${adminId}`, 'PATCH', {
            fullName: profileData.fullName,
            address: profileData.address,
            city: profileData.city,
            postal_code: profileData.postal_code
        });
        setMsg(response.msg);
        if (response.ok) {
            window.history.replaceState({}, document.title);
        } else {
        }

        setIsLoading(false);
    };

    return (
        <>
            <div>
                <SettingsTitle title={'Your Details'} />
                <div className="view-profile-area">
                    <div className="view-profile-top">
                        <div className="view-profile-left">
                            <div className="image-on-profile">
                                <Avatar
                                    size={50}
                                    name={profileData ? profileData.fullName : ''}
                                    className="partnerImage"
                                />
                            </div>
                            <div className="settings-profile-name">
                                {!isLoading && (
                                    <h2>{profileData ? profileData.fullName : 'Full Name'}</h2>
                                )}
                                {isLoading && <Shimmer width={'300px'} height={'32px'} />}
                            </div>
                        </div>
                    </div>
                    <div className="view-profile">
                        <Form method="post" className={'settings-form-area'}>
                            {isLoading && (
                                <>
                                    <Shimmer width={'90%'} height={'50px'} />
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    <Shimmer width={'90%'} height={'50px'} />
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    <Shimmer width={'90%'} height={'50px'} />
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    <Shimmer width={'90%'} height={'50px'} />
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                </>
                            )}
                            {!isLoading && profileData && (
                                <>
                                    <TextInput
                                        type="text"
                                        label="Full Name"
                                        value={profileData.fullName}
                                        onChange={(e) =>
                                            setProfileData({
                                                ...profileData,
                                                fullName: e.target.value
                                            })
                                        }
                                    />
                                    <TextInput
                                        type="text"
                                        label="User Name"
                                        value={profileData ? profileData.userName : 'User Name'}
                                        disabled
                                    />
                                    <TextInput
                                        type="text"
                                        label="Phone Number"
                                        value={profileData ? profileData.phone : 'Phone'}
                                        disabled
                                    />
                                    <TextInput
                                        type="text"
                                        label="Email Address"
                                        value={profileData ? profileData.email : 'Email'}
                                        disabled
                                    />
                                    <TextInput
                                        type="text"
                                        label="Address"
                                        value={profileData ? profileData.address : 'Address' || ''}
                                        onChange={(e) =>
                                            setProfileData({
                                                ...profileData,
                                                address: e.target.value
                                            })
                                        }
                                    />
                                    <TextInput
                                        type="text"
                                        label="Postal Code"
                                        value={
                                            profileData
                                                ? profileData.postal_code
                                                : 'Postal Code' || ''
                                        }
                                        onChange={(e) =>
                                            setProfileData({
                                                ...profileData,
                                                postal_code: e.target.value
                                            })
                                        }
                                    />
                                    <TextInput
                                        type="text"
                                        label="City"
                                        value={profileData ? profileData.city : 'City' || ''}
                                        onChange={(e) =>
                                            setProfileData({
                                                ...profileData,
                                                city: e.target.value
                                            })
                                        }
                                    />
                                    <TextInput
                                        type="text"
                                        label="Role"
                                        value={
                                            profileData ? profileData.admin_role.role_name : 'Role'
                                        }
                                        disabled
                                    />
                                </>
                            )}
                            <div className="form-button">
                                <Button onClick={updateAdminProfile} className={'btn-add'}>
                                    Update
                                </Button>
                                <Button onClick={() => navigate(-1)} className={'btn-close'}>
                                    Cancel
                                </Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Profile;
