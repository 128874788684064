import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Shimmer } from 'react-shimmer';
import apiCall from '../../../../api/apiCall';
import { getAccessToken } from '../../../Login/Auth';
import Button from '../../../Shared/Form/Button/Button';
import Form from '../../../Shared/Form/Form';
import TextInput from '../../../Shared/Form/TextInput/TextInput';
import './AddRole.css';

const AddRole = (props) => {
    const navigate = useNavigate();
    let token = getAccessToken();
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState();
    const [roleData, setRoleData] = useState();
    const [role_name, setRoleName] = useState([]);
    const [to_currency, setToCurrency] = useState();
    const [exchange_rate, setExchangeRate] = useState();
    if (token != null) token = token.replace(/['"]+/g, '');
    useEffect(() => {
        document.title = 'Add Role';
    });

    const addRole = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const response = await apiCall(`/role`, 'POST', {
            role_name: [role_name]
        });
        if (response.ok) {
            setMsg('Success');
        } else {
            setMsg(response.msg);
        }

        setIsLoading(false);
    };

    const getRoles = async () => {
        setIsLoading(true);

        const response = await apiCall(`/roles`);
        if (response.ok) {
            setRoleData(response.data);
            setMsg('Success');
        } else {
            setMsg(response.msg);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        getRoles();
    }, []);

    return (
        <>
            <div className="role-form-area">
                <h3>Add Role</h3>
                {msg && <div className="msg">{msg}</div>}
                <Form method="post" className={'role-form form-area'}>
                    {isLoading && (
                        <>
                            <Shimmer width={'90%'} height={'50px'} />
                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                        </>
                    )}
                    {!isLoading && (
                        <>
                            <TextInput
                                label="Role Name"
                                type="text"
                                name="role_name"
                                required
                                onChange={(e) => setRoleName(e.target.value)}
                            />
                        </>
                    )}
                    <div className="form-button">
                        <Button onClick={addRole} disabled={!role_name} className={'btn-add'}>
                            Add
                        </Button>
                        <Button onClick={() => navigate(-1)} className={'btn-close'}>
                            Cancel
                        </Button>
                    </div>
                </Form>
            </div>
        </>
    );
};

export default AddRole;
