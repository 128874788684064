import moment from 'moment/moment';
import { useEffect, useState } from 'react';
import { Shimmer } from 'react-shimmer';
import apiCall from '../../../api/apiCall';
import { getAccessToken } from '../../Login/Auth';
import LinkButton from '../../Shared/LinkButton/LinkButton';
import NoDataFound from '../../Shared/NoDataFound/NoDataFound';
import './Customers.css';
const Customers = (props) => {
    const linkBtn = {
        text: 'Add Customer',
        btnBG: '#2980FF',
        btnColor: '#ffffff',
        link: '/dashboard/view/customers'
    };
    let token = getAccessToken();
    const [isLoading, setIsLoading] = useState(false);
    const [customerData, setCustomerData] = useState();
    const [msg, setMsg] = useState();
    if (token != null) token = token.replace(/['"]+/g, '');
    const [searchedData, setSearchedData] = useState();
    useEffect(() => {
        document.title = 'Customer';
    });

    const getCustomers = async () => {
        setIsLoading(true);

        const response = await apiCall(`/users`);
        if (response.ok) {
            setCustomerData(response.data);
            setMsg('Success');
        } else {
            setMsg(response.msg);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        getCustomers();
    }, []);

    useEffect(() => {
        if (customerData) {
            setSearchedData(customerData);
        }
    }, [customerData]);

    useEffect(() => {
        if (customerData && props.searchKey) {
            setSearchedData(
                customerData.filter((data) =>
                    data.user.fullName.toLowerCase().includes(props.searchKey.toLowerCase())
                )
            );
        } else {
            setSearchedData(customerData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.searchKey]);

    let fields;
    if (searchedData) {
        fields = searchedData.map((data) => (
            <tr key={data.user.id}>
                <th>
                    <span className="checkbox">
                        <input type="checkbox"></input>
                    </span>
                    <span>#{data.user.id}</span>
                </th>

                <th>{data.user.fullName}</th>
                <th>{data.user.email}</th>
                <th>{data.user.phone}</th>
                <th>{data.user.country.country_name}</th>
                <th>{data.balance.toFixed(2)}</th>
                <th>{!data.user.isVerified ? 'Not Verified' : 'Verified'}</th>
                <th>{moment(new Date(data.createdAt), 'MMDDYYYY').fromNow()}</th>
            </tr>
        ));
    }

    return (
        <>
            <div className="admin-area">
                <div className="admin-table-area">
                    <div className="table-top">
                        <div className="result-show">
                            <span>Total Customer: </span>
                            {!isLoading && <span>{searchedData ? searchedData.length : ''}</span>}
                        </div>
                        <LinkButton linkBtn={linkBtn} />
                    </div>
                    <div className="invoice-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <span className="checkbox">
                                            <input type="checkbox"></input>
                                        </span>
                                        <span>Id</span>
                                    </th>
                                    <th>Full Name</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>Country Name</th>
                                    <th>Account Balance</th>
                                    <th>Verification Status</th>
                                    <th>Joined On</th>
                                </tr>
                            </thead>
                            {!isLoading && msg && (
                                <tbody>{fields ? fields : <NoDataFound data="Customers" />}</tbody>
                            )}
                        </table>
                        {isLoading && (
                            <>
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Customers;
