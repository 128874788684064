import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Shimmer } from 'react-shimmer';
import apiCall from '../../../../api/apiCall';

import Button from '../../../Shared/Form/Button/Button';
import Form from '../../../Shared/Form/Form';
import TextInput from '../../../Shared/Form/TextInput/TextInput';
import './EditFees';
const EditFees = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState();

    const [feesData, setFeesData] = useState();
    const [currencyData, setCurrencyData] = useState();

    const [currenciesJSX, setCurrenciesJSX] = useState();

    useEffect(() => {
        document.title = 'Update Withdraw Fee';
    });

    const updateWithdrawFee = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const response = await apiCall(`/withdraw_fees/${id}`, 'PATCH', feesData);

        if (response.ok) {
            setMsg(response.msg);
        } else {
            setMsg(response.msg);
        }

        setIsLoading(false);
    };
    useEffect(() => {
        if (location.state) setFeesData(location.state);
        getCurrencies();
    }, [location]);

    console.log(feesData);

    useEffect(() => {
        if (currencyData && currencyData.length > 0) {
            setCurrenciesJSX(
                currencyData.map((data) => (
                    <option key={data.id} value={data.id}>
                        {data.currency_name}
                    </option>
                ))
            );
        }
    }, [currencyData]);

    const getCurrencies = async () => {
        setIsLoading(true);

        const response = await apiCall(`/currencies`);

        if (response.ok) {
            setCurrencyData(response.data);
            setMsg('Success');
        } else {
            setMsg(response.msg);
        }

        setIsLoading(false);
    };

    return (
        <>
            <div className="edit-exchange-form-area">
                <h3>Edit Withdraw Fees (USD)</h3>
                {msg && <div className="msg">{msg}</div>}
                {feesData && (
                    <Form method="post" className={'edit-exchange-form form-area'}>
                        {isLoading && (
                            <>
                                <Shimmer width={'90%'} height={'50px'} />
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <Shimmer width={'90%'} height={'50px'} />
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <Shimmer width={'90%'} height={'50px'} />
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <Shimmer width={'90%'} height={'50px'} />
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                            </>
                        )}
                        {!isLoading && (
                            <>
                                <div className="form-field">
                                    <label>From Currency</label>
                                    <select
                                        className="select-box"
                                        onChange={(e) =>
                                            setFeesData({
                                                ...feesData,
                                                from_currency: e.target.value
                                            })
                                        }
                                        value={feesData.from_currency}
                                    >
                                        {currenciesJSX}
                                    </select>
                                </div>
                                <div className="form-field">
                                    <label>To Currency</label>
                                    <select
                                        className="select-box"
                                        onChange={(e) =>
                                            setFeesData({
                                                ...feesData,
                                                to_currency: e.target.value
                                            })
                                        }
                                        value={feesData.to_currency}
                                    >
                                        {currenciesJSX}
                                    </select>
                                </div>

                                <TextInput
                                    label="Name"
                                    type="text"
                                    required
                                    onChange={(e) =>
                                        setFeesData({ ...feesData, name: e.target.value })
                                    }
                                    value={feesData.name}
                                />
                                <TextInput
                                    label="Value"
                                    type="text"
                                    required
                                    onChange={(e) =>
                                        setFeesData({ ...feesData, value: e.target.value })
                                    }
                                    value={feesData.value}
                                />
                                <div className="form-field">
                                    <label>Fee Currency</label>
                                    <select
                                        className="select-box"
                                        onChange={(e) =>
                                            setFeesData({
                                                ...feesData,
                                                fee_currency: e.target.value
                                            })
                                        }
                                        value={feesData.fee_currency}
                                    >
                                        {currenciesJSX}
                                    </select>
                                </div>
                                <TextInput
                                    label="Withdraw Time(Min)"
                                    type="number"
                                    required
                                    onChange={(e) =>
                                        setFeesData({ ...feesData, withdraw_time: e.target.value })
                                    }
                                    value={feesData.withdraw_time}
                                />
                                <TextInput
                                    label="Description"
                                    type="text"
                                    onChange={(e) =>
                                        setFeesData({ ...feesData, description: e.target.value })
                                    }
                                    value={feesData.description}
                                />
                            </>
                        )}
                        <div className="form-button">
                            <Button className={'btn-add'} onClick={updateWithdrawFee}>
                                Update
                            </Button>
                            <Button onClick={() => navigate(-1)} className={'btn-close'}>
                                Cancel
                            </Button>
                        </div>
                    </Form>
                )}
            </div>
        </>
    );
};

export default EditFees;
