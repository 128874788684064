import { useEffect, useState } from 'react';
import { FiEdit } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { Shimmer } from 'react-shimmer';
import apiCall from '../../../api/apiCall';
import { getAccessToken } from '../../Login/Auth';
import LinkButton from '../../Shared/LinkButton/LinkButton';
import NoDataFound from '../../Shared/NoDataFound/NoDataFound';

import './Transactions.css';
const Transactions = (props) => {
    const linkBtn = {
        text: 'Add New',
        btnBG: '#2980FF',
        btnColor: '#ffffff',
        link: '/dashboard/item'
    };
    let token = getAccessToken();
    const [isLoading, setIsLoading] = useState(false);
    const [statusName, setStatusName] = useState('Settlement');
    const [transacitonData, setTransacitonData] = useState();
    const [filterData, setFilteredData] = useState();
    const [msg, setMsg] = useState();
    if (token != null) token = token.replace(/['"]+/g, '');
    const [offsetOptions, setOffsetOptions] = useState([]);
    const [paginationData, setPaginationData] = useState({
        limit: 100,
        offset: 0
    });
    const [paginationDataFromAPI, setPaginationDataFromAPI] = useState();

    useEffect(() => {
        document.title = 'Transaction';
    });

    const getTransactions = async () => {
        setIsLoading(true);
        const response = await apiCall(
            `/transactions?limit=${paginationData.limit}&offset=${paginationData.offset}`
        );
        console.log(response.data);
        if (response.ok) {
            setTransacitonData(response.data.data);
            setPaginationDataFromAPI({
                total: response.data.totalPages,
                current: response.data.currentPage
            });
            setMsg('Success');
        } else {
            setMsg(response.msg);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        getTransactions();
    }, [paginationData]);

    useEffect(() => {
        if (transacitonData && statusName !== 'All') {
            const data = transacitonData.filter((value) => value.status === statusName);
            setFilteredData(data);
        } else {
            setFilteredData(transacitonData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusName, transacitonData]);

    let fields;
    if (filterData) {
        fields = filterData.map((data) => (
            <tr key={data.transaction_id}>
                <th>
                    <span className="checkbox">
                        <input type="checkbox"></input>
                    </span>
                    <span>#{data.transaction_id}</span>
                </th>
                <th>{data.transaction_type}</th>
                <th>{data.status}</th>
                <th>{data.currency.currency_name}</th>
                <th>
                    {data?.payment?.invoice_id
                        ? 'Invoice'
                        : data?.payment?.paylink_id
                        ? 'LinkPay'
                        : ''}
                </th>
                <th>{data.user.fullName}</th>
                <th>{data.user.email}</th>
                <th>{data.amount_out}</th>
                <th>{data.amount_in}</th>
                <th>{data?.payment?.payment_method?.method_name}</th>
                <th>{new Date(data.createdAt).toLocaleDateString()}</th>
                <th>
                    <div className="action-btn">
                        <Link
                            className="edit-icon"
                            to={`/dashboard/edit/transaction/${data.transaction_id}`}
                            state={{ data, allData: transacitonData }}
                        >
                            <FiEdit />
                        </Link>
                    </div>
                </th>
            </tr>
        ));
    }

    useEffect(() => {
        if (
            paginationDataFromAPI &&
            paginationDataFromAPI.total &&
            paginationDataFromAPI.total > 0
        ) {
            let options = [];
            for (let i = 0; i < paginationDataFromAPI.total; i++) {
                options.push(
                    <option key={i} value={i}>
                        {i + 1}
                    </option>
                );
            }
            setOffsetOptions(options);
        }
    }, [paginationDataFromAPI]);

    return (
        <>
            <div className="transactions-area">
                <div className="transactions-table-area">
                    <div className="table-top">
                        <div className="transaction-result-show">
                            <span>Status: </span>
                            <select
                                defaultValue={statusName}
                                onChange={(e) => setStatusName(e.target.value)}
                            >
                                <option key="All" value="All">
                                    All
                                </option>
                                <option key="Hold" value="Hold">
                                    Hold
                                </option>
                                <option key="Pending" value="Pending">
                                    Pending
                                </option>
                                <option key="Complete" value="Complete">
                                    Complete
                                </option>
                                <option key="Reject" value="Reject">
                                    Reject
                                </option>
                                <option key="Settlement" value="Settlement">
                                    Settlement
                                </option>
                            </select>
                        </div>
                        <div className="transaction-result-show">
                            <span>Limit: </span>
                            <select
                                defaultValue={paginationData.limit}
                                onChange={(e) =>
                                    setPaginationData({ offset: 0, limit: e.target.value })
                                }
                            >
                                <option key="10" value={10}>
                                    10
                                </option>
                                <option key="20" value={20}>
                                    20
                                </option>
                                <option key="25" value={25}>
                                    25
                                </option>
                                <option key="30" value={30}>
                                    30
                                </option>
                                <option key="50" value={50}>
                                    50
                                </option>
                                <option key="100" value={100}>
                                    100
                                </option>
                            </select>
                        </div>
                        <div className="transaction-result-show">
                            <span>Page: </span>
                            <select
                                defaultValue={0}
                                onChange={(e) =>
                                    setPaginationData({ ...paginationData, offset: e.target.value })
                                }
                            >
                                {offsetOptions}
                            </select>
                        </div>
                        <LinkButton linkBtn={linkBtn} />
                    </div>
                    <div className="transactions-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <span className="checkbox">
                                            <input type="checkbox"></input>
                                        </span>
                                        <span>Trasaction Id</span>
                                    </th>
                                    <th>Transaction Type</th>
                                    <th>Status</th>
                                    <th>Currency Name</th>
                                    <th>Type</th>
                                    <th>Full Name</th>
                                    <th>Email</th>
                                    <th>Amount Out</th>
                                    <th>Amount In</th>
                                    <th>Payment Method</th>
                                    <th>Date</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            {!isLoading && msg && (
                                <tbody>
                                    {fields ? fields : <NoDataFound data="Transactions" />}
                                </tbody>
                            )}
                        </table>
                        {isLoading && (
                            <>
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Transactions;
