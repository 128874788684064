import { useEffect, useState } from 'react';
import { GrFormView } from 'react-icons/gr';
import { Link } from 'react-router-dom';
import { Shimmer } from 'react-shimmer';
import apiCall from '../../../api/apiCall';
import { getAccessToken } from '../../Login/Auth';
import LinkButton from '../../Shared/LinkButton/LinkButton';
import NoDataFound from '../../Shared/NoDataFound/NoDataFound';
import moment from 'moment/moment';
import './Invoice.css';
const Invoice = () => {
    const linkBtn = {
        text: 'Add New',
        btnBG: '#2980FF',
        btnColor: '#ffffff',
        link: '/dashboard/view/invoices'
    };
    let token = getAccessToken();
    if (token != null) token = token.replace(/['"]+/g, '');
    const [isLoading, setIsLoading] = useState(false);
    const [statusName, setStatusName] = useState('All');
    const [filterData, setFilteredData] = useState();
    const [invoiceData, setInvoiceData] = useState();
    const [msg, setMsg] = useState();
    useEffect(() => {
        document.title = 'Invoice';
    });

    const getAllInvoices = async () => {
        setIsLoading(true);

        const response = await apiCall(`/invoices`);
        if (response.ok) {
            setInvoiceData(response.data);
            setMsg('Success');
        } else {
            setMsg(response.msg);
        }

        setIsLoading(false);
    };
    useEffect(() => {
        getAllInvoices();
    }, []);

    useEffect(() => {
        if (invoiceData && statusName !== 'All') {
            const data = invoiceData.filter((data) => data.isPaid == statusName);
            setFilteredData(data);
        } else {
            setFilteredData(invoiceData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusName, invoiceData]);

    let fields;
    if (filterData) {
        fields = filterData.map((data) => (
            <tr key={data.invoice_id}>
                <th>
                    <span className="checkbox">
                        <input type="checkbox"></input>
                    </span>
                    <span>#{data.invoice_id}</span>
                </th>
                <th>{data.user.email}</th>
                <th>{data.client_email}</th>
                <th>{data.description}</th>
                <th>{data.isPaid ? 'Paid' : 'Not Paid'}</th>
                <th>{data.paid_time ? new Date(data.paid_time).toLocaleDateString() : ''}</th>
                <th>{moment(new Date(data.createdAt), 'MMDDYYYY').fromNow()}</th>
                <th>
                    <div className="action-btn">
                        <Link
                            className="view-icon"
                            to={`/dashboard/view/invoice/${data.invoice_id}`}
                            state={data}
                        >
                            <GrFormView />
                        </Link>
                    </div>
                </th>
            </tr>
        ));
    }

    return (
        <>
            <div className="invoice-area">
                <div className="invoice-table-area">
                    <div className="table-top">
                        <div className="result-show">
                            <span>Status: </span>
                            <select
                                defaultValue={statusName}
                                onChange={(e) => setStatusName(e.target.value)}
                            >
                                <option key="All" value="All">
                                    All
                                </option>
                                <option key={1} value={1}>
                                    Paid
                                </option>
                                <option key={0} value={0}>
                                    Not Paid
                                </option>
                            </select>
                        </div>
                        <div className="result-show">
                            <span>Total Invoice: </span>
                            {!isLoading && <span>{invoiceData ? invoiceData.length : ''}</span>}
                        </div>
                        <LinkButton linkBtn={linkBtn} />
                    </div>
                    <div className="invoice-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <span className="checkbox">
                                            <input type="checkbox"></input>
                                        </span>
                                        <span>Invoice Id</span>
                                    </th>
                                    <th>User Email</th>
                                    <th>Client Email</th>

                                    <th>Description</th>
                                    <th>Status</th>
                                    <th>Paid Date</th>
                                    <th>Created At</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            {!isLoading && msg && (
                                <tbody>{fields ? fields : <NoDataFound data="Invoices" />}</tbody>
                            )}
                        </table>
                        {isLoading && (
                            <>
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Invoice;
