import AddGatewayFee from 'components/Dashboard/GatewayFee/AddGatewayFee/AddGatewayFee';
import EditGatewayFee from 'components/Dashboard/GatewayFee/EditGatewayFee/EditGatewayFee';
import GatewayFee from 'components/Dashboard/GatewayFee/GatewayFee';
import { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Currency from '../Dashboard/Currency/Currency';
import Customers from '../Dashboard/Customers/Customers';
import Document from '../Dashboard/Document/Document';

import EditDocument from '../Dashboard/Document/EditDocument/EditDocument';
import AddExchangeRate from '../Dashboard/ExchangeRate/AddExchangeRate/AddExchangeRate';
import EditExchangeRate from '../Dashboard/ExchangeRate/EditExchangeRate/EditExchangeRate';
import ExchangeRate from '../Dashboard/ExchangeRate/ExchangeRate';
import AddFees from '../Dashboard/Fees/AddFees/AddFees';
import EditFees from '../Dashboard/Fees/EditFees/EditFees';
import Fees from '../Dashboard/Fees/Fees';
import Invoice from '../Dashboard/Invoice/Invoice';
import ViewSingleInvoice from '../Dashboard/Invoice/ViewSingleInvoice/ViewSingleInvoice';
import Overview from '../Dashboard/Overview/Overview';
import AddRole from '../Dashboard/Role/AddRole/AddRole';
import EditRole from '../Dashboard/Role/EditRole/EditRole';
import Role from '../Dashboard/Role/Role';
import ChangePassword from '../Dashboard/Settings/ChangePassword/ChangePassword';
import Notification from '../Dashboard/Settings/Notification/Notificaiton';
import Profile from '../Dashboard/Settings/Profile/Profile';
import Settings from '../Dashboard/Settings/Settings';
import EditTransaction from '../Dashboard/Transacitons/EditTransaction/EditTransaction';
import Transactions from '../Dashboard/Transacitons/Transactions';
import EditWithdraw from '../Dashboard/Withdraw/EditWithdraw/EditWithdraw';
import Withdraws from '../Dashboard/Withdraw/Withdraw';
import AddWithdrawPolicy from '../Dashboard/WithdrawPolicy/AddWithdrawPolicy/AddWithdrawPolicy';
import EditWithdrawPolicy from '../Dashboard/WithdrawPolicy/EditWithdraw/EditPolicy';
import WithdrawPolicy from '../Dashboard/WithdrawPolicy/WithdrawPolicy';
import Header from '../Header/Header';
import NotFound from '../NotFound/NotFound';
import Sidebar from '../Sidebar/Sidebar';
import './Layout.css';
const Layout = () => {
    const [searchKey, setSearchKey] = useState();
    const [isSideMenuOpen, setIsSideMenuOpen] = useState(true);

    const setSearchKeyFunction = (key) => {
        setSearchKey(key);
    };
    const setIsSideMenuOpenFunction = () => {
        setIsSideMenuOpen(!isSideMenuOpen);
    };

    return (
        <BrowserRouter>
            <Sidebar isSideMenuOpen={isSideMenuOpen} />
            <div className={!isSideMenuOpen ? 'side-menu-open main' : 'main'}>
                <Header
                    setIsSideMenuOpenFunction={setIsSideMenuOpenFunction}
                    setSearchKeyFunction={setSearchKeyFunction}
                />
                <Routes>
                    <Route path="/dashboard" element={<Overview />} />
                    <Route path="/dashboard/policy" element={<WithdrawPolicy />} />
                    <Route path="/dashboard/add/policy" element={<AddWithdrawPolicy />} />
                    <Route path="/dashboard/edit/policy/:id" element={<EditWithdrawPolicy />} />
                    <Route path="/dashboard/fees" element={<Fees />} />
                    <Route path="/dashboard/add/fees" element={<AddFees />} />
                    <Route path="/dashboard/edit/fees/:id" element={<EditFees />} />

                    <Route path="/dashboard/gateway_fee" element={<GatewayFee />} />
                    <Route path="/dashboard/add/gateway_fee" element={<AddGatewayFee />} />
                    <Route path="/dashboard/edit/gateway_fee/:id" element={<EditGatewayFee />} />

                    <Route path="/dashboard/view/transactions" element={<Transactions />} />
                    <Route path="/dashboard/edit/transaction/:id" element={<EditTransaction />} />
                    <Route
                        path="/dashboard/view/customers"
                        element={<Customers searchKey={searchKey} />}
                    />
                    <Route path="/dashboard/view/profile" element={<Profile />} />
                    <Route path="/dashboard/view/withdraws" element={<Withdraws />} />
                    <Route path="/dashboard/edit/withdraw/:id" element={<EditWithdraw />} />
                    <Route path="/dashboard/view/invoices" element={<Invoice />} />
                    <Route
                        path="/dashboard/view/invoice/:invoice_id"
                        element={<ViewSingleInvoice />}
                    />
                    <Route
                        path="/dashboard/view/documents"
                        // render={(props) => <Document {...props} ref={docRef} />}
                        element={<Document />}
                    />
                    <Route path="/dashboard/edit/document/:id" element={<EditDocument />} />
                    <Route
                        path="/dashboard/view/exchange_rates"
                        element={<ExchangeRate searchKey={searchKey} />}
                    />
                    <Route path="/dashboard/add/exchnage_rate" element={<AddExchangeRate />} />
                    <Route
                        path="/dashboard/edit/exchange_rate/:id"
                        element={<EditExchangeRate />}
                    />
                    <Route
                        path="/dashboard/view/currencies"
                        element={<Currency searchKey={searchKey} />}
                    />
                    <Route path="/dashboard/view/roles" element={<Role />} />
                    <Route path="/dashboard/add/role" element={<AddRole />} />
                    <Route path="/dashboard/edit/role/:id" element={<EditRole />} />
                    <Route path="/dashboard/settings" element={<Settings />}>
                        <Route path="profile" element={<Profile />} />
                        <Route path="change-password" element={<ChangePassword />} />
                        <Route path="notification" element={<Notification />} />
                    </Route>
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </div>
        </BrowserRouter>
    );
};

export default Layout;
