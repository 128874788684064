import { useEffect, useState } from 'react';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { Shimmer } from 'react-shimmer';
import apiCall from '../../../api/apiCall';
import LinkButton from '../../Shared/LinkButton/LinkButton';
import NoDataFound from '../../Shared/NoDataFound/NoDataFound';
import './ExchangeRate.css';

const ExchangeRate = (props) => {
    const linkBtn = {
        text: 'Add New',
        btnBG: '#2980FF',
        btnColor: '#ffffff',
        link: '/dashboard/add/exchnage_rate'
    };

    const [isLoading, setIsLoading] = useState(false);
    const [searchedData, setSearchedData] = useState();
    const [exchangeRate, setExchangeRate] = useState();
    const [deleteExchangeRate, setDeleteExchangeRate] = useState();
    const [msg, setMsg] = useState();
    useEffect(() => {
        document.title = 'Exchange Rate';
    });

    const getExchangeRates = async () => {
        setIsLoading(true);

        const response = await apiCall(`/exchange_rate`);

        if (response.ok) {
            setExchangeRate(response.data);
            setMsg('Success');
        } else {
            setMsg(response.msg);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        getExchangeRates();
    }, []);

    useEffect(() => {
        if (exchangeRate) {
            setSearchedData(exchangeRate);
        }
    }, [exchangeRate]);

    useEffect(() => {
        if (exchangeRate && props.searchKey) {
            setSearchedData(
                exchangeRate.filter((data) =>
                    data.exchange_code.toLowerCase().includes(props.searchKey.toLowerCase())
                )
            );
        } else {
            setSearchedData(exchangeRate);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.searchKey]);

    const deleteRate = async (id) => {
        setIsLoading(true);

        const response = await apiCall(`/exchange_rate/${id}`, 'DELETE');

        if (response.ok) {
            setMsg(response.message);
            setDeleteExchangeRate(deleteExchangeRate.filter((data) => data.id !== id));
        } else {
            setMsg(response.message);
        }

        setIsLoading(false);
    };
    let fields;
    if (searchedData) {
        fields = searchedData.map((data) => (
            <tr key={data.id}>
                <th>
                    <span className="checkbox">
                        <input type="checkbox"></input>
                    </span>
                    <span>#{data.id}</span>
                </th>
                <th>{data.from_currency_data.currency_name}</th>
                <th>{data.from_currency_data.code}</th>
                <th>{data.to_currency_data.currency_name}</th>
                <th>{data.to_currency_data.code}</th>
                <th>{data.exchange_code}</th>
                <th>{data.exchange_rate}</th>
                <th>{new Date(data.createdAt).toLocaleDateString()}</th>
                <th>
                    <div className="action-btn">
                        <Link
                            className="edit-icon"
                            to={`/dashboard/edit/exchange_rate/${data.id}`}
                            state={data}
                        >
                            <FiEdit />
                        </Link>
                        <button className="delete-icon" onClick={() => deleteRate(data.id)}>
                            <RiDeleteBin6Line />
                        </button>
                    </div>
                </th>
            </tr>
        ));
    }
    return (
        <>
            <div className="exchnage-rate-area">
                <div className="exchnage-rate-table-area">
                    <div className="table-top">
                        <div className="result-show">
                            <span>Results: </span>
                            <select name="" id="">
                                <option value="0">05</option>
                                <option value="0">06</option>
                                <option value="0">07</option>
                                <option value="0">08</option>
                                <option value="0">09</option>
                                <option value="0">10</option>
                            </select>
                        </div>
                        <LinkButton linkBtn={linkBtn} />
                    </div>
                    <div className="exchnage-rate-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <span className="checkbox">
                                            <input type="checkbox"></input>
                                        </span>
                                        <span>Id</span>
                                    </th>
                                    <th>From Currency Name</th>
                                    <th>From Currency Code</th>
                                    <th>To Currency Name</th>
                                    <th>To Currency Code</th>
                                    <th>Exchange Code</th>
                                    <th>Exchange Rate</th>
                                    <th>Date</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            {!isLoading && msg && (
                                <tbody>
                                    {fields ? fields : <NoDataFound data="Exchnage Rates" />}
                                </tbody>
                            )}
                        </table>
                        {isLoading && (
                            <>
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ExchangeRate;
