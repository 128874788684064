import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button2 from '../Shared/Form/Button/Button2';
import FormTitle from '../Shared/Form/FormTitle/FormTitle';
import TextInput from '../Shared/Form/TextInput/TextInput';
import FormLayout from '../Shared/FormLayout/FormLayout';
function SignUp() {
    const [email, setEmail] = useState();
    const title = 'Sign up';
    const subtitle = 'Enter your registered mobile number to log in.';
    useEffect(() => {
        document.title = 'Sign Up';
    });

    return (
        <FormLayout>
            <div className="form-top">
                <div>
                    <p>Already have an account?</p>
                </div>
                <div>
                    <Link to="/" className="auth-form-button">
                        Log in
                    </Link>
                </div>
            </div>
            <div className="form-fields-area">
                <FormTitle title={title} subtitle={subtitle} />
                <form>
                    <TextInput
                        onChange={(e) => setEmail(e.target.value)}
                        type="text"
                        placeholder="Email address"
                        label={'Email address'}
                        required
                    />

                    <div className="form-button-area">
                        <Button2 type="submit" disabled={!email}>
                            SIGN UP
                        </Button2>
                    </div>
                </form>
            </div>
        </FormLayout>
    );
}

export default SignUp;
