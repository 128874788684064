import './SettingsTitle.css';

function SettingsTitle({ title, subtitle }) {
    return (
        <div className="settings-title">
            <h3>{title}</h3>
            {subtitle && <p>{subtitle}</p>}
        </div>
    );
}

export default SettingsTitle;
