import { useEffect, useState } from 'react';
import { IoCaretBackSharp } from 'react-icons/io5';
import { Link, useLocation, useParams } from 'react-router-dom';
import apiCall from '../../../../api/apiCall';
import Loading from '../../../Loading/Loading';
import { getAccessToken } from '../../../Login/Auth';
import Button from '../../../Shared/Form/Button/Button';
import Form from '../../../Shared/Form/Form';
import TextInput from '../../../Shared/Form/TextInput/TextInput';
import './ViewSingleInvoice.css';
const ViewSingleInvoice = (props) => {
    const { invoice_id } = useParams();
    const location = useLocation();
    const [invoiceData, setInvoiceData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState();
    let token = getAccessToken();
    if (token != null) token = token.replace(/['"]+/g, '');
    useEffect(() => {
        document.title = 'Single Invoice';
    });

    const getInvoice = async (invoice_id) => {
        setIsLoading(true);

        const response = await apiCall(`/invoice/${invoice_id}`);

        if (response.ok) {
            setInvoiceData(response.data);
            setMsg('Success');
        } else {
            setMsg(response.msg);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        if (location.state) setInvoiceData(location.state);
        else getInvoice(invoice_id);
    }, []);
    return (
        <>
            {isLoading && <Loading />}
            {!isLoading && invoiceData && (
                <div className="single-invoice-form-area">
                    <h3>View Invoice ID: {invoiceData.invoice_id}</h3>
                    {msg && <p>{msg}</p>}
                    <Form method="post" className={'single-invoice-form form-area'}>
                        <TextInput
                            label="User Name"
                            type="text"
                            value={invoiceData.user.fullName}
                            required
                            disabled
                        />
                        <TextInput
                            label="Client Name"
                            type="text"
                            value={invoiceData.client_name}
                            required
                            disabled
                        />
                        <TextInput
                            label="Client Email"
                            type="text"
                            name="client_email"
                            value={invoiceData.client_email}
                            required
                            disabled
                        />
                        <TextInput label="Amount" type="text" value={invoiceData.amount} disabled />
                        <TextInput
                            label="Currency Code"
                            type="text"
                            name="code"
                            disabled
                            value={invoiceData.currency.code}
                        />
                        <TextInput
                            label="Currency Name"
                            type="text"
                            name="currency_name"
                            required
                            value={invoiceData.currency.currency_name}
                            disabled
                        />
                        <TextInput
                            label="Description"
                            type="text"
                            name="description"
                            value={invoiceData.description}
                            disabled
                        />

                        <TextInput label="isPaid" type="checkbox" checked={invoiceData.isPaid} />

                        <div className="form-button">
                            <Link className="view-icon" to={'/dashboard/view/invoices'}>
                                <Button>
                                    <IoCaretBackSharp />
                                    Go Back
                                </Button>
                            </Link>
                        </div>
                    </Form>
                </div>
            )}
        </>
    );
};

export default ViewSingleInvoice;
