import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Shimmer } from 'react-shimmer';
import apiCall from '../../../../api/apiCall';
import { getAccessToken } from '../../../Login/Auth';
import Button from '../../../Shared/Form/Button/Button';
import Form from '../../../Shared/Form/Form';
import TextInput from '../../../Shared/Form/TextInput/TextInput';
import './EditRole.css';
const EditRole = (props) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    let token = getAccessToken();
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState();
    const [roleData, setRoleData] = useState();
    if (token != null) token = token.replace(/['"]+/g, '');
    useEffect(() => {
        document.title = 'Update Role';
    });

    const getRole = async (e) => {
        setIsLoading(true);

        const response = await apiCall(`/role/${id}`);

        if (response.ok) {
            setMsg('Success');
        } else {
            setMsg(response.message);
        }

        setIsLoading(false);
    };
    const updateRole = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const response = await apiCall(`/role/${id}`, 'PATCH', {
            role_name: roleData.role_name
        });
        setMsg(response.msg);
        if (response.ok) {
            window.history.replaceState({}, document.title);
        } else {
        }

        setIsLoading(false);
    };
    useEffect(() => {
        if (location.state) setRoleData(location.state);
        else getRole();
    }, []);

    return (
        <>
            <div className="edit-role-form-area">
                <h3>Edit Role</h3>
                {msg && <div className="msg">{msg}</div>}
                {roleData && (
                    <Form method="post" className={'edit-role-form form-area'}>
                        {isLoading && (
                            <>
                                <Shimmer width={'90%'} height={'50px'} />
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                            </>
                        )}
                        {!isLoading && (
                            <>
                                <TextInput
                                    label="Role Name"
                                    value={roleData.role_name || ''}
                                    onChange={(e) =>
                                        setRoleData({
                                            ...roleData,
                                            role_name: e.target.value
                                        })
                                    }
                                />
                            </>
                        )}
                        <div className="form-button">
                            <Button className={'btn-add'} onClick={updateRole}>
                                Update
                            </Button>
                            <Button onClick={() => navigate(-1)} className={'btn-close'}>
                                Cancel
                            </Button>
                        </div>
                    </Form>
                )}
            </div>
        </>
    );
};

export default EditRole;
