import logo from '../../../images/logo.png';
import './FormLayout.css';

function FormLayout({ children }) {
    return (
        <div className="form-page">
            <div className="form-left">
                <div className="form-logo">
                    <img src={logo} alt="" />
                </div>
            </div>
            <div className="form-right">{children}</div>
        </div>
    );
}

export default FormLayout;
