import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import apiCall from '../../../../api/apiCall';
import { getAccessToken } from '../../../Login/Auth';
import Button from '../../../Shared/Form/Button/Button';
import Form from '../../../Shared/Form/Form';
import TextInput from '../../../Shared/Form/TextInput/TextInput';
import SettingsTitle from '../../../Shared/SettingsTitle/SettingsTitle';
function ChangePassword() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [passwordData, setPasswordData] = useState({});
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [msg, setMsg] = useState();
    const token = getAccessToken();
    useEffect(() => {
        document.title = 'Change Password';
    }, []);

    const changePassword = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const response = await apiCall(`/change_password`, 'PATCH', {
            password: passwordData.current,
            new_password: passwordData.new,
            confirm_password: passwordData.confirm
        });
        setMsg(response.msg);
        if (response.ok) {
        } else {
        }

        setIsLoading(false);
    };

    const passWordValidator = (password) => {
        if (password.length < 8) {
            setMsg('Password Must be at least 8 characters long');
            return false;
        } else if (!password.match(/\d/) || !password.match(/[a-zA-Z]/)) {
            setMsg('Password Must Contain one number and one letter');
            return false;
        } else {
            return true;
        }
    };

    useEffect(() => {
        setIsPasswordValid(false);
        setMsg('');
        if (passwordData.new) passWordValidator(passwordData.new);
        if (passwordData.confirm) {
            if (passwordData.new !== passwordData.confirm) setMsg('Password Did not match');
        }
        if (passwordData.new && passwordData.confirm && passwordData.current) {
            if (passWordValidator(passwordData.new) && passwordData.new === passwordData.confirm) {
                setIsPasswordValid(true);
            }
        }
    }, [passwordData.new, passwordData.confirm, passwordData.password]);

    return (
        <>
            <div>
                <SettingsTitle title={'Change Password'} />
                <div className="view-profile-area">
                    {msg && <p>{msg}</p>}
                    <div className="view-profile">
                        <Form onSubmit={changePassword} className={'settings-form-area'}>
                            <TextInput
                                type="password"
                                label="Current Password"
                                value={passwordData.current}
                                required
                                onChange={(e) => {
                                    setPasswordData({
                                        ...passwordData,
                                        current: e.target.value
                                    });
                                }}
                            />
                            <br></br>
                            <TextInput
                                type="password"
                                label="New Password"
                                required
                                value={passwordData.new}
                                onChange={(e) => {
                                    setPasswordData({
                                        ...passwordData,
                                        new: e.target.value
                                    });
                                }}
                            />
                            <br></br>
                            <TextInput
                                type="password"
                                label="Confirm Password"
                                required
                                value={passwordData.confirm}
                                onChange={(e) => {
                                    setPasswordData({
                                        ...passwordData,
                                        confirm: e.target.value
                                    });
                                }}
                            />
                            <br></br>
                            <div className="form-button">
                                <Button onClick={changePassword} className={'btn-add'}>
                                    Save Password
                                </Button>
                                <Button onClick={() => navigate(-1)} className={'btn-close'}>
                                    Cancel
                                </Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ChangePassword;
