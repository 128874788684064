import React from 'react';
import { AiOutlineAppstore } from 'react-icons/ai';
import { BiDownload } from 'react-icons/bi';
import { FiLogOut, FiSettings } from 'react-icons/fi';
import { GrDocumentUser, GrTransaction } from 'react-icons/gr';
import { IoIosArrowDown } from 'react-icons/io';
import { MdAttachMoney, MdOutlinePolicy, MdOutlineSubtitles } from 'react-icons/md';
import { TbCurrency, TbExchange, TbFileInvoice, TbUsers } from 'react-icons/tb';

import { NavLink, useNavigate } from 'react-router-dom';
import { logout } from '../Login/Auth';

import logo from '../../images/logo.svg';
import './Sidebar.css';

const Sidebar = (props) => {
    const [isOpen, setIsOpen] = React.useState(true);
    const navigate = useNavigate();
    return (
        <div className={!props.isSideMenuOpen ? 'sidebar-closed sidebar' : 'sidebar'}>
            <div className="logo">
                <img src={logo} alt="" />
            </div>
            <div className="nav-menu">
                <div className="menu-list menu-bg" onClick={() => setIsOpen(!isOpen)}>
                    <div className="menu-list-left menu-whilte">
                        <span className="menu-list-left-icon">
                            <AiOutlineAppstore />
                        </span>
                        <span
                            className={
                                !props.isSideMenuOpen
                                    ? 'sidebar-closed-menu'
                                    : 'menu-list-left-text'
                            }
                        >
                            Dashboard
                        </span>
                    </div>
                    <div
                        className={
                            !props.isSideMenuOpen
                                ? 'sidebar-closed-menu'
                                : 'menu-list-right menu-whilte'
                        }
                    >
                        <span>
                            <IoIosArrowDown />
                        </span>
                    </div>
                </div>
                {isOpen && (
                    <>
                        <p
                            className={
                                !props.isSideMenuOpen ? 'sidebar-closed-menu' : 'screen-name'
                            }
                        >
                            Application Screen
                        </p>
                        <div className="menu-list">
                            <NavLink
                                to="/dashboard/view/customers"
                                className={({ isActive }) =>
                                    isActive ? 'active-class' : 'no-active-class'
                                }
                            >
                                <div className="menu-list-left">
                                    <span className="menu-list-left-icon">
                                        <TbUsers />
                                    </span>
                                    <span
                                        className={
                                            !props.isSideMenuOpen
                                                ? 'sidebar-closed-menu'
                                                : 'menu-list-left-text'
                                        }
                                    >
                                        Customers
                                    </span>
                                </div>
                            </NavLink>
                        </div>
                        <div className="menu-list">
                            <NavLink
                                to="/dashboard/view/transactions"
                                className={({ isActive }) =>
                                    isActive ? 'active-class' : 'no-active-class'
                                }
                            >
                                <div className="menu-list-left">
                                    <span className="menu-list-left-icon">
                                        <GrTransaction />
                                    </span>
                                    <span
                                        className={
                                            !props.isSideMenuOpen
                                                ? 'sidebar-closed-menu'
                                                : 'menu-list-left-text'
                                        }
                                    >
                                        Transaction
                                    </span>
                                </div>
                            </NavLink>
                        </div>
                        <div className="menu-list">
                            <NavLink
                                to="/dashboard/view/withdraws"
                                className={({ isActive }) =>
                                    isActive ? 'active-class' : 'no-active-class'
                                }
                            >
                                <div className="menu-list-left">
                                    <span className="menu-list-left-icon">
                                        <BiDownload />
                                    </span>
                                    <span
                                        className={
                                            !props.isSideMenuOpen
                                                ? 'sidebar-closed-menu'
                                                : 'menu-list-left-text'
                                        }
                                    >
                                        Withdraws
                                    </span>
                                </div>
                            </NavLink>
                        </div>
                        <div className="menu-list">
                            <NavLink
                                to="/dashboard/view/invoices"
                                className={({ isActive }) =>
                                    isActive ? 'active-class' : 'no-active-class'
                                }
                            >
                                <div className="menu-list-left">
                                    <span className="menu-list-left-icon">
                                        <TbFileInvoice />
                                    </span>
                                    <span
                                        className={
                                            !props.isSideMenuOpen
                                                ? 'sidebar-closed-menu'
                                                : 'menu-list-left-text'
                                        }
                                    >
                                        Invoices
                                    </span>
                                </div>
                            </NavLink>
                        </div>
                        <div className="menu-list">
                            <NavLink
                                to="/dashboard/view/documents"
                                className={({ isActive }) =>
                                    isActive ? 'active-class' : 'no-active-class'
                                }
                            >
                                <div className="menu-list-left">
                                    <span className="menu-list-left-icon">
                                        <GrDocumentUser />
                                    </span>
                                    <span
                                        className={
                                            !props.isSideMenuOpen
                                                ? 'sidebar-closed-menu'
                                                : 'menu-list-left-text'
                                        }
                                    >
                                        Documents
                                    </span>
                                </div>
                            </NavLink>
                        </div>
                        <div className="menu-list">
                            <NavLink
                                to="/dashboard/policy"
                                className={({ isActive }) =>
                                    isActive ? 'active-class' : 'no-active-class'
                                }
                            >
                                <div className="menu-list-left">
                                    <span className="menu-list-left-icon">
                                        <MdOutlinePolicy />
                                    </span>
                                    <span
                                        className={
                                            !props.isSideMenuOpen
                                                ? 'sidebar-closed-menu'
                                                : 'menu-list-left-text'
                                        }
                                    >
                                        Withdraw Policy
                                    </span>
                                </div>
                            </NavLink>
                        </div>
                        <div className="menu-list">
                            <NavLink
                                to="/dashboard/gateway_fee"
                                className={({ isActive }) =>
                                    isActive ? 'active-class' : 'no-active-class'
                                }
                            >
                                <div className="menu-list-left">
                                    <span className="menu-list-left-icon">
                                        <MdAttachMoney />
                                    </span>
                                    <span
                                        className={
                                            !props.isSideMenuOpen
                                                ? 'sidebar-closed-menu'
                                                : 'menu-list-left-text'
                                        }
                                    >
                                        Gateway Fees
                                    </span>
                                </div>
                            </NavLink>
                        </div>
                        <div className="menu-list">
                            <NavLink
                                to="/dashboard/fees"
                                className={({ isActive }) =>
                                    isActive ? 'active-class' : 'no-active-class'
                                }
                            >
                                <div className="menu-list-left">
                                    <span className="menu-list-left-icon">
                                        <MdAttachMoney />
                                    </span>
                                    <span
                                        className={
                                            !props.isSideMenuOpen
                                                ? 'sidebar-closed-menu'
                                                : 'menu-list-left-text'
                                        }
                                    >
                                        Withdraw Fees
                                    </span>
                                </div>
                            </NavLink>
                        </div>
                        <div className="menu-list">
                            <NavLink
                                to="/dashboard/view/exchange_rates"
                                className={({ isActive }) =>
                                    isActive ? 'active-class' : 'no-active-class'
                                }
                            >
                                <div className="menu-list-left">
                                    <span className="menu-list-left-icon">
                                        <TbExchange />
                                    </span>
                                    <span
                                        className={
                                            !props.isSideMenuOpen
                                                ? 'sidebar-closed-menu'
                                                : 'menu-list-left-text'
                                        }
                                    >
                                        Exchange Rate
                                    </span>
                                </div>
                            </NavLink>
                        </div>
                        <div className="menu-list">
                            <NavLink
                                to="/dashboard/view/currencies"
                                className={({ isActive }) =>
                                    isActive ? 'active-class' : 'no-active-class'
                                }
                            >
                                <div className="menu-list-left">
                                    <span className="menu-list-left-icon">
                                        <TbCurrency />
                                    </span>
                                    <span
                                        className={
                                            !props.isSideMenuOpen
                                                ? 'sidebar-closed-menu'
                                                : 'menu-list-left-text'
                                        }
                                    >
                                        Currency
                                    </span>
                                </div>
                            </NavLink>
                        </div>
                        <div className="menu-list">
                            <NavLink
                                to="/dashboard/view/roles"
                                className={({ isActive }) =>
                                    isActive ? 'active-class' : 'no-active-class'
                                }
                            >
                                <div className="menu-list-left">
                                    <span className="menu-list-left-icon">
                                        <MdOutlineSubtitles />
                                    </span>
                                    <span
                                        className={
                                            !props.isSideMenuOpen
                                                ? 'sidebar-closed-menu'
                                                : 'menu-list-left-text'
                                        }
                                    >
                                        Role
                                    </span>
                                </div>
                            </NavLink>
                        </div>
                        <div className="menu-list">
                            <NavLink
                                to="/dashboard/settings"
                                className={({ isActive }) =>
                                    isActive ? 'active-class' : 'no-active-class'
                                }
                            >
                                <div className="menu-list-left">
                                    <span className="menu-list-left-icon">
                                        <FiSettings />
                                    </span>
                                    <span
                                        className={
                                            !props.isSideMenuOpen
                                                ? 'sidebar-closed-menu'
                                                : 'menu-list-left-text'
                                        }
                                    >
                                        Settings
                                    </span>
                                </div>
                            </NavLink>
                        </div>
                        <div className="menu-list">
                            <div
                                className="menu-list-left"
                                onClick={() => {
                                    logout();
                                    navigate('/');
                                    window.location.reload();
                                }}
                            >
                                <div className="menu-list-left">
                                    <span className="menu-list-left-icon">
                                        <FiLogOut />
                                    </span>
                                    <span
                                        className={
                                            !props.isSideMenuOpen
                                                ? 'sidebar-closed-menu'
                                                : 'menu-list-left-text'
                                        }
                                    >
                                        Logout
                                    </span>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Sidebar;
