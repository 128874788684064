import { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import { BiSearch } from 'react-icons/bi';
import { FiMenu } from 'react-icons/fi'; //FiShoppingCart
// import { HiOutlineMail } from 'react-icons/hi';
// import { RiNotification3Line } from 'react-icons/ri';
import apiCall from '../../api/apiCall';
import { getAccessToken } from '../Login/Auth';
import './Header.css';
const Header = (props) => {
    const token = getAccessToken();
    const [adminData, setAdminData] = useState();
    const [msg, setMsg] = useState();
    const getLoggedInAdmin = async (id) => {
        const response = await apiCall(`/profile/${id}`);

        if (response.ok) {
            setAdminData(response.data.admin);
            setMsg('Sucess');
        } else {
            setMsg(response.data.message);
        }
    };
    useEffect(() => {
        const adminId = JSON.parse(atob(token.split('.')[1]));
        getLoggedInAdmin(adminId.sub);
    }, []);

    return (
        <div className="header">
            <div className="menu-icon" onClick={props.setIsSideMenuOpenFunction}>
                <FiMenu />
            </div>
            <div className="search">
                <span>
                    <BiSearch />
                </span>
                <input
                    type="text"
                    placeholder="Search"
                    onChange={(e) => props.setSearchKeyFunction(e.target.value)}
                />
            </div>
            <div className="top-right">
                {/* <div className="notification-icon">
                    <span>
                        <HiOutlineMail />
                    </span>
                    <span>
                        <FiShoppingCart />
                    </span>
                    <span>
                        <RiNotification3Line />
                    </span>
                </div> */}
                <div className="profile">
                    <div>
                        <Avatar
                            size={50}
                            name={adminData ? adminData.fullName : ''}
                            className="partnerImage"
                        />
                        {/* <img
                            className="partnerImage"
                            alt="photo_url"
                            src={
                                adminData && adminData.photo_url
                                    ? `${process.env.REACT_APP_UPLOAD_URL}/v1/image/${adminData.photo_url}`
                                    : 'https://via.placeholder.com/150'
                            }
                        /> */}
                    </div>
                    <div>
                        <p>{adminData ? adminData.fullName : 'John Doe'}</p>
                        <span>
                            {adminData && adminData.admin_role
                                ? adminData.admin_role.role_name
                                : 'Revorium Admin'}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
