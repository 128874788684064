import React, { useEffect, useState } from 'react';
import { Shimmer } from 'react-shimmer';
import apiCall from '../../../api/apiCall';
import { getAccessToken } from '../../Login/Auth';
import LinkButton from '../../Shared/LinkButton/LinkButton';
import NoDataFound from '../../Shared/NoDataFound/NoDataFound';

import './Currency.css';
const Currency = (props) => {
    const linkBtn = {
        text: 'Add New',
        btnBG: '#2980FF',
        btnColor: '#ffffff',
        link: 'dashboard/view/currencies'
    };
    let token = getAccessToken();
    if (token != null) token = token.replace(/['"]+/g, '');
    const [isLoading, setIsLoading] = useState(false);
    const [currencyData, setCurrencyData] = useState();
    const [searchedData, setSearchedData] = useState();
    const [msg, setMsg] = useState();
    useEffect(() => {
        document.title = 'Currency';
    });

    const getAllCurrencies = async () => {
        setIsLoading(true);

        const response = await apiCall(`/currencies`);
        if (response.ok) {
            setCurrencyData(response.data);
            setMsg('Success');
        } else {
            setMsg(response.msg);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        getAllCurrencies();
    }, []);

    useEffect(() => {
        if (currencyData) {
            setSearchedData(currencyData);
        }
    }, [currencyData]);

    useEffect(() => {
        if (currencyData && props.searchKey) {
            setSearchedData(
                currencyData.filter((data) =>
                    data.currency_name.toLowerCase().includes(props.searchKey.toLowerCase())
                )
            );
        } else {
            setSearchedData(currencyData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.searchKey]);

    let fields;
    if (searchedData) {
        fields = searchedData.map((data) => (
            <tr key={data.id}>
                <th>
                    <span className="checkbox">
                        <input type="checkbox"></input>
                    </span>
                    <span>#{data.id}</span>
                </th>
                <th>{data.currency_name}</th>
                <th>{data.code}</th>
                <th>{data.country_id}</th>
                <th>{data.country.calling_code}</th>
                <th>{data.country.iso2Code}</th>
                <th>{data.country.country_name}</th>
                <th>{new Date(data.createdAt).toLocaleDateString()}</th>
            </tr>
        ));
    }

    return (
        <>
            <div className="currency-area">
                <div className="currency-table-area">
                    <div className="table-top">
                        <div className="result-show">
                            <span>Results: </span>
                            <select name="" id="">
                                <option value="0">05</option>
                                <option value="0">06</option>
                                <option value="0">07</option>
                                <option value="0">08</option>
                                <option value="0">09</option>
                                <option value="0">10</option>
                            </select>
                        </div>
                        <LinkButton linkBtn={linkBtn} />
                    </div>
                    <div className="currency-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <span className="checkbox">
                                            <input type="checkbox"></input>
                                        </span>
                                        <span>Currency Id</span>
                                    </th>
                                    <th>Currency Name</th>
                                    <th>Currency Code</th>
                                    <th>Country Code</th>
                                    <th>Calling Code</th>
                                    <th>ISO2 Code</th>
                                    <th>Country Name</th>
                                    <th>Creation Date</th>
                                </tr>
                            </thead>
                            {!isLoading && msg && (
                                <tbody>{fields ? fields : <NoDataFound data="Currencies" />}</tbody>
                            )}
                        </table>
                        {isLoading && (
                            <>
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                                <br></br>
                                <Shimmer width={'100%'} height={'62px'} />
                                <br></br>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Currency;
