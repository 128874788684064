import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Shimmer } from 'react-shimmer';
import apiCall from '../../../../api/apiCall';
import { getAccessToken } from '../../../Login/Auth';
import Button from '../../../Shared/Form/Button/Button';
import Form from '../../../Shared/Form/Form';
import TextInput from '../../../Shared/Form/TextInput/TextInput';
import './EditExchangeRate.css';
const EditExchangeRate = (props) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    let token = getAccessToken();
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState();
    const [exchnageData, setExchangeData] = useState();
    const [exchange_rate, setExchangeRate] = useState();
    if (token != null) token = token.replace(/['"]+/g, '');
    useEffect(() => {
        document.title = 'Update Exchange Rate';
    });

    const getExchangeRate = async (e) => {
        setIsLoading(true);

        const response = await apiCall(`/exchange_rate/${id}`);

        if (response.ok) {
            setMsg('Success');
        } else {
            setMsg(response.msg);
        }

        setIsLoading(false);
    };
    const updateExchangeRate = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const response = await apiCall(`/exchange_rate/${id}`, 'PATCH', {
            exchange_rate: exchnageData.exchange_rate
        });

        if (response.ok) {
            setMsg(response.msg);
            window.history.replaceState({}, document.title);
        } else {
            setMsg(response.msg);
        }

        setIsLoading(false);
    };
    useEffect(() => {
        if (location.state) setExchangeData(location.state);
        else getExchangeRate();
    }, []);

    return (
        <>
            <div className="edit-exchange-form-area">
                <h3>Edit Exchange Rate</h3>
                {msg && <div className="msg">{msg}</div>}
                {exchnageData && (
                    <Form method="post" className={'edit-exchange-form form-area'}>
                        {isLoading && (
                            <>
                                <Shimmer width={'90%'} height={'50px'} />
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <Shimmer width={'90%'} height={'50px'} />
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <Shimmer width={'90%'} height={'50px'} />
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <Shimmer width={'90%'} height={'50px'} />
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                            </>
                        )}
                        {!isLoading && (
                            <>
                                <TextInput
                                    label="From Currecy Name"
                                    type="text"
                                    value={exchnageData.from_currency_data.currency_name}
                                    disabled
                                />
                                <TextInput
                                    label="To Currecy Name"
                                    type="text"
                                    value={exchnageData.to_currency_data.currency_name}
                                    disabled
                                />
                                <TextInput
                                    label="From Currency Code"
                                    type="text"
                                    value={exchnageData.from_currency_data.code}
                                    disabled
                                />
                                <TextInput
                                    label="To Currency Code"
                                    type="text"
                                    value={exchnageData.to_currency_data.code}
                                    disabled
                                />
                                <TextInput
                                    label="Exchange Rate"
                                    type="text"
                                    value={exchnageData.exchange_rate || ''}
                                    onChange={(e) =>
                                        setExchangeData({
                                            ...exchnageData,
                                            exchange_rate: e.target.value
                                        })
                                    }
                                />
                                <TextInput
                                    label="Exchange Code"
                                    type="text"
                                    value={exchnageData.exchange_code}
                                    disabled
                                />
                            </>
                        )}
                        <div className="form-button">
                            <Button className={'btn-add'} onClick={updateExchangeRate}>
                                Update
                            </Button>
                            <Button onClick={() => navigate(-1)} className={'btn-close'}>
                                Cancel
                            </Button>
                        </div>
                    </Form>
                )}
            </div>
        </>
    );
};

export default EditExchangeRate;
